import { Injectable, InjectionToken, inject } from '@angular/core';

import { Deferred } from '@frontend/sports/common/base-utils';
import { ApiBase, ApiServiceFactory, ClientConfigProductName, ProductService } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';

export class Api extends ApiBase {}

export const API = new InjectionToken<Api>('sports-api', {
    providedIn: 'root',
    factory: () => {
        const factory = inject(ApiServiceFactory);
        const productService = inject(ProductService);

        return apiServiceFactory(factory, productService);
    },
});

export function apiServiceFactory(factory: ApiServiceFactory, productService: ProductService): Api {
    return factory.create(Api, {
        product: ClientConfigProductName.SPORTS,
        area: 'sports',
        forwardProductApiRequestHeader: productService.getMetadata(ClientConfigProductName.SPORTS).isEnabled,
    });
}

@Injectable({ providedIn: 'root' })
export class ApiService {
    private readonly api = inject(API);

    get<T>(
        url: string,
        params?: unknown,
        options?: {
            showSpinner?: boolean;
            withCredentials?: boolean;
            cancelationToken?: Deferred<void>;
            retryCount?: number;
            retryDelay?: number;
            retryOnStatus?: number[];
            headers?: { [key: string]: string };
            responseType?: 'json' | 'text';
            resolveWithFullResponse?: boolean;
        },
    ): Observable<T> {
        const resultOptions = options ?? {
            showSpinner: false,
            withCredentials: true,
        };
        resultOptions.withCredentials = resultOptions.withCredentials === false ? false : true;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.api.get(url, params || {}, {
            showSpinner: resultOptions.showSpinner,
            withCredentials: resultOptions.withCredentials,
            retryCount: resultOptions.retryCount,
            retryDelay: resultOptions.retryDelay,
            retryOnStatus: resultOptions.retryOnStatus,
            headers: resultOptions.headers,
            responseType: resultOptions.responseType,
            resolveWithFullResponse: resultOptions.resolveWithFullResponse,
        });
    }

    post<T>(
        url: string,
        data?: unknown,
        options?: {
            showSpinner?: boolean;
            withCredentials?: boolean;
            cancelationToken?: Deferred<void>;
            cache?: boolean;
            retryCount?: number;
            retryDelay?: number;
            headers?: { [key: string]: string };
            responseType?: 'json' | 'text';
            resolveWithFullResponse?: boolean;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            params?: { [key: string]: any };
        },
    ): Observable<T> {
        let headers = {};

        const resultOptions = options ?? {
            showSpinner: false,
            resolveWithFullResponse: false,
        };

        if (!resultOptions.cache) {
            headers = { 'cache-control': 'no-cache' };
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.api.post(url, data, {
            headers,
            showSpinner: resultOptions.showSpinner,
            withCredentials: resultOptions.withCredentials,
            retryCount: resultOptions.retryCount,
            retryDelay: resultOptions.retryDelay,
            responseType: resultOptions.responseType,
            resolveWithFullResponse: resultOptions.resolveWithFullResponse,
            params: resultOptions.params,
        });
    }
}
