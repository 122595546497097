import { Injectable } from '@angular/core';

import { Fixture } from '@cds/betting-offer';
import { CDNCachingConfig } from '@frontend/sports/common/client-config-data-access';
import { WidgetLayoutResponse, WidgetType } from '@frontend/sports/types/components/widget';
import { ComposableWidgetPayload } from '@frontend/sports/types/components/widget/types';
import { flatten, includes, uniqBy } from 'lodash-es';
import { Observable, of } from 'rxjs';

import { ISchemaMarkupResolver } from '../../schema-markup/schema-markup.resolver';

@Injectable({ providedIn: 'root' })
export class WidgetSchemaMarkupResolver implements ISchemaMarkupResolver<WidgetLayoutResponse> {
    private readonly widgetsWithFixtures = [WidgetType.NextToGo, WidgetType.TabbedGrid, WidgetType.OutrightsGrid];

    private readonly widgetsWithFixturesOrComposable = [...this.widgetsWithFixtures, WidgetType.Composable];

    constructor(private cdnCachingConfig: CDNCachingConfig) {
        this.widgetsWithFixturesOrComposable = this.widgetsWithFixturesOrComposable.filter((items) => !this.cdnCachingConfig.widgets.includes(items));
        this.widgetsWithFixtures = this.widgetsWithFixtures.filter((items) => !this.cdnCachingConfig.widgets.includes(items));
    }

    resolve(model: WidgetLayoutResponse): Observable<Array<Fixture>> {
        if (!model?.widgets?.length) {
            return of([]);
        }

        const widgetsToConsider = flatten(
            model.widgets
                .filter((w) => includes(this.widgetsWithFixturesOrComposable, w?.type))
                .map((widget) =>
                    widget.type === WidgetType.Composable
                        ? (widget.payload as ComposableWidgetPayload)?.items?.flatMap(
                              (slot) => slot?.activeChildren?.filter((w) => includes(this.widgetsWithFixtures, w?.type)) ?? [],
                          )
                        : [widget],
                ),
        );

        const uniqueFixtures = uniqBy(
            widgetsToConsider.flatMap((w) => (w.payload as { fixtures: Fixture[] }).fixtures),
            (f) => f.id,
        );

        return of(uniqueFixtures);
    }
}
