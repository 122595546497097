import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { Participant, ParticipantImage, ParticipantType } from '@cds/betting-offer';
import { NumberDictionary, formatString } from '@frontend/sports/common/base-utils';
import { ImagesConfig, OutrightsGridConfig } from '@frontend/sports/common/client-config-data-access';
import { ParticipantImageFormat } from '@frontend/sports/types/components/outrights-grid';

import { EventModel, EventOption, EventOptionGroup, EventParticipant, EventParticipantImage } from '../event-model/model/event.model';
import { Tv2MarketParameters } from '../event-model/model/tv2.model';

export const getParticipantImageMap = (participants: Participant[]): Map<number, ParticipantImage> => {
    const participantImageMap = new Map();
    participants?.forEach((participant) => {
        if (participant.source === OfferSource.V1) {
            participantImageMap.set(participant.participantId, participant.image);
        }
    });
    return participantImageMap;
};

export const getOptionImage = (
    participants: Participant[],
    option: EventOption,
    participantImageMap: Map<number | undefined, ParticipantImage>,
): ParticipantImage => {
    const participant: Participant | undefined = participants?.find(
        (p) =>
            p.properties?.type === ParticipantType.Player &&
            ((p.participantId !== undefined && p.participantId === option.player1Id) ||
                (p.name?.value !== undefined && p.name?.value === option.player1) ||
                (p.name?.short !== undefined && p.name?.short === option.player1) ||
                (p.participantId !== undefined && p.participantId === option.playerId)),
    );

    const teamImage = participantImageMap.get(participant?.properties?.team);
    return { ...participant?.image, logoColor: teamImage?.logoColor } as ParticipantImage;
};

@Injectable()
export class ParticipantImageService {
    optionImages: NumberDictionary<EventParticipantImage> = {};
    enableOptionImages: boolean;
    constructor(
        private outrightsGridConfig: OutrightsGridConfig,
        private imageConfig: ImagesConfig,
    ) {}

    showOrHideOptionImages(
        options: EventOption[],
        sportId: number | undefined,
        offerSource: OfferSource | undefined,
    ): [boolean, NumberDictionary<EventParticipantImage>] {
        this.optionImages = {};
        this.enableOptionImages =
            !!sportId &&
            this.outrightsGridConfig.enableParticipantImages.includes(sportId) &&
            options.some((option) => (option.teamId ? !!option.teamId : !!option.playerId));
        if (this.enableOptionImages) {
            options.map((option) => (this.optionImages[option.id] = this.getParticipantImage(option, sportId, offerSource)));
        }

        return [this.enableOptionImages, this.optionImages];
    }

    getPlayerJersey(event: EventModel, optionGroup: EventOptionGroup): EventOption[] {
        if (!optionGroup?.options) {
            return [];
        }

        return optionGroup.options.map((option) => {
            option.playerId = option.playerId || option.optionPlayerId;

            if (option.playerId) {
                option.teamId = this.getTeamId(event.players, option.playerId) ?? this.getTeamId(event.participants, option.playerId);
                option.image =
                    event.players.find((player) => player.id === option.teamId)?.image ??
                    event.participants.find((participant) => participant.id === option.teamId)?.image;
            }

            return option;
        });
    }

    private getTeamId(participants: EventParticipant[], optionPlayerId: number): number | undefined {
        const participant = participants.find((p) => p.fixtureParticipantId === optionPlayerId);

        return participant?.teamId ?? participant?.id;
    }

    getParticipantImage(
        option: EventOption,
        sportId: number | undefined,
        offerSource: OfferSource | undefined,
        showHomeAndAwayJerseys?: boolean,
    ): EventParticipantImage {
        if (!option.playerId) {
            return {};
        }
        const overrideFormat = this.outrightsGridConfig.overrideParticipantImageNameFormat.find((format) => format.sportId === sportId);
        const playerId = option.playerId.toString();
        const teamId = option.teamId?.toString();

        return {
            isParticipantProfile: option?.image && option?.image?.isParticipantProfile,
            logo: !teamId
                ? option?.image && option?.image?.isParticipantProfile
                    ? option.image?.logo
                    : formatString(overrideFormat?.logoNameFormat || '{0}_logo', playerId)
                : undefined,
            jersey:
                option?.image && option?.image?.isParticipantProfile
                    ? option.image?.jersey
                    : this.getJerseyName(teamId || playerId, offerSource, option, overrideFormat, showHomeAndAwayJerseys),
        };
    }

    private getJerseyName(
        playerId: string,
        offerSource: OfferSource | undefined,
        option: EventOption,
        overrideFormat?: ParticipantImageFormat,
        showHomeAndAwayJerseys?: boolean,
    ): string {
        let jerseyNameFormat = overrideFormat?.jerseyNameFormat || '{0}_1';
        if (offerSource === OfferSource.V2) {
            if (showHomeAndAwayJerseys) {
                jerseyNameFormat = option.image?.jersey || `2_${jerseyNameFormat}`;
            } else {
                jerseyNameFormat = `2_${jerseyNameFormat}`;
            }
        }

        return formatString(jerseyNameFormat, playerId);
    }

    showOptionsImage(event: EventModel, optionGroup: EventOptionGroup): boolean {
        return (
            event.offerSource === OfferSource.V2 &&
            this.imageConfig.isParticipantImageEnabled &&
            this.imageConfig.participantImageEnabledMarketTypes[event.sport?.id]?.includes(
                optionGroup.parameters?.find((p) => p.key === Tv2MarketParameters.MarketType)?.value!,
            )
        );
    }

    showHeadshotImage(sportId: number, optionGroup: string): boolean {
        return (!!sportId && this.imageConfig.headshotEnabledOptionGroups[sportId]?.includes(optionGroup)) || false;
    }
}
