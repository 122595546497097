import { Injectable, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';

import { FixtureBase } from '@cds/betting-offer';
import { SportConstant } from '@frontend/sports/common/base-utils';
import { YahooOpenIdConfig } from '@frontend/sports/common/client-config-data-access';
import {
    CookieService,
    GoToOptions,
    LoginProvider,
    MenuAction,
    MenuActionsService,
    NativeAppService,
    NavigationService,
    WindowRef,
} from '@frontend/vanilla/core';
import { LoginProvidersService } from '@frontend/vanilla/shared/login-providers';
import { Subject, concatMap, distinctUntilKeyChanged, tap, windowTime } from 'rxjs';

import { NavigationHelper } from './navigation-helper.service';
import { UrlParam } from './url-builder';
import {
    FavouritesDashboardUrlParam,
    LiveUrlParam,
    MarketUrlParam,
    MyBetsUrlParam,
    RacingUrlParam,
    SettingsUrlParam,
    SportUrlParam,
    UrlHelperService,
} from './url-helper.service';

export enum YahooOpenIdTriggerOption {
    BetSlip = 'betslip',
}

export interface GoToLink {
    url: string;
    options: GoToOptions;
}

@Injectable({ providedIn: 'root' })
export class RedirectHelperService {
    private readonly navigationSubject$ = new Subject<GoToLink>();

    constructor(
        private windowRef: WindowRef,
        navigation: NavigationService,
        private navigationHelper: NavigationHelper,
        private urlHelper: UrlHelperService,
        private menuActionsService: MenuActionsService,
        private loginWithProvidersService: LoginProvidersService,
        private cookieService: CookieService,
        private yahooOpenidconfig: YahooOpenIdConfig,
        private nativeAppService: NativeAppService,
        ngZone: NgZone,
    ) {
        ngZone.runOutsideAngular(() =>
            this.navigationSubject$
                .pipe(
                    windowTime(300),
                    concatMap((obs) => obs.pipe(distinctUntilKeyChanged('url'))),
                    tap((redirect: GoToLink) => navigation.goTo(redirect.url, redirect.options)),
                    takeUntilDestroyed(),
                )
                .subscribe(),
        );
    }

    goToLiveSport(options: { sport: UrlParam; region?: UrlParam; ignoreBrowserHistory?: boolean; isEsport?: boolean }): void {
        this.goToPage(
            this.urlHelper.getLiveSportUrl({ sport: options.sport, region: options.region, isEsport: options.isEsport }),
            options.ignoreBrowserHistory,
        );
    }

    goToLive(tab: LiveUrlParam = LiveUrlParam.Highlights, tracking?: string): void {
        this.goToPage(this.urlHelper.getLiveUrl(tab, tracking));
    }

    goToSport(sport: UrlParam, context?: SportUrlParam, subContext?: SportUrlParam.Competitions): void;
    goToSport(sport: UrlParam, region?: UrlParam, league?: UrlParam): void;
    goToSport(sport: UrlParam, regionOrContext?: UrlParam | SportUrlParam, leagueOrSubContext?: UrlParam | SportUrlParam.Competitions): void {
        let url: string;

        if (!regionOrContext || typeof regionOrContext === 'string') {
            url = this.urlHelper.getSportUrl(sport, regionOrContext as SportUrlParam, leagueOrSubContext as SportUrlParam.Competitions);
        } else {
            url = this.urlHelper.getSportUrl(sport, SportUrlParam.Betting, regionOrContext, leagueOrSubContext as UrlParam);
        }

        this.goToPage(url);
    }

    goToVirtualSport(sport: UrlParam, competition?: UrlParam, event?: string): void {
        this.goToPage(this.urlHelper.getVirtualSportUrl(sport, competition, event));
    }

    goToEvent(event: UrlParam, market?: MarketUrlParam): void {
        this.goToPage(this.urlHelper.getEventUrl(event, market));
    }

    goToLogin(options?: {
        forceReload?: boolean;
        appendReferrer?: boolean | string;
        referrerNeedsLoggedInUser?: boolean;
        useYahooLogin?: boolean;
    }): void {
        const returnUrl = typeof options?.appendReferrer === 'string' ? options.appendReferrer : '';
        if (options?.useYahooLogin && this.isYahooOpenIdFlow()) {
            this.loginWithProvidersService.urlAuth({
                provider: LoginProvider.YAHOO,
                redirectQueryParams: { trigger: YahooOpenIdTriggerOption.BetSlip },
            });
        } else {
            this.menuActionsService.invoke(MenuAction.GOTO_LOGIN, '', [undefined, undefined, { returnUrl }]);
        }
    }

    goToHorseRacing(page: RacingUrlParam): void;
    goToHorseRacing(meeting: UrlParam, filterOrRace?: RacingUrlParam | UrlParam): void;
    goToHorseRacing(param: RacingUrlParam | UrlParam, filterOrRace?: RacingUrlParam | UrlParam): void {
        if (typeof param === 'string') {
            this.goToPage(this.urlHelper.getRaceUrl(SportConstant.Horses, param));
        } else if (param) {
            this.goToPage(this.urlHelper.getRaceUrl(SportConstant.Horses, param, filterOrRace));
        }
    }

    goToFixtureByFixture(fixture: Pick<FixtureBase, 'id' | 'name' | 'sport' | 'fixtureType' | 'meeting'>): void {
        this.goToPage(this.urlHelper.getFixtureUrlByFixture(fixture));
    }

    goToFixture(sport: UrlParam, fixture: UrlParam, prefix?: string): void {
        this.goToPage(this.urlHelper.getFixtureUrl(sport, fixture, prefix));
    }

    goToSettings(settings: SettingsUrlParam): void {
        this.goToPage(this.urlHelper.getSettingsUrl(settings));
    }

    goToFavouriteWithUrlParam(urlParam: FavouritesDashboardUrlParam): void {
        this.goToPage(this.urlHelper.getFavouriteUrlByUrlParam(urlParam));
    }

    goHome(ignoreBrowserHistory?: boolean): void {
        this.goToPage(this.urlHelper.getHomeUrl(), ignoreBrowserHistory ?? true);
    }

    goToMyBets(route?: MyBetsUrlParam, trackingParams?: Params, ignoreBrowserHistory: boolean = true): void {
        this.goToPage(this.urlHelper.getMyBetsUrl(route, trackingParams), ignoreBrowserHistory);
    }

    goToPopup(popup: 'betfinder' | 'betbuilder'): void {
        this.goToPage(`?popup=${popup}`);
    }

    goToPage(url?: string, ignoreBrowserHistory: boolean = false): void {
        if (!url) {
            return;
        }

        this.navigationSubject$.next({ url, options: { replace: ignoreBrowserHistory } });
    }

    goToRacing(sportId: SportConstant, param: RacingUrlParam | UrlParam, filterOrRace?: RacingUrlParam | UrlParam): void {
        this.goToPage(this.urlHelper.getRaceUrl(sportId, param, filterOrRace));
    }

    goBack(url?: string): void {
        if (url) {
            this.goToPage(url);

            return;
        }

        if (this.navigationHelper.forceHomePage() && !this.userIsComingFromPortalPromotions) {
            this.goToPage('/');

            return;
        }

        this.windowRef.nativeWindow.history.back();
    }

    reloadPage(): void {
        this.windowRef.nativeWindow.location.reload();
    }

    private isYahooOpenIdFlow(): boolean {
        const nativeAppValue = this.yahooOpenidconfig.nativeAppCookie || '';
        const loginHint = !!this.cookieService.get('login_hint');
        const idTokenHint = !!this.cookieService.get('id_token_hint');

        return this.yahooOpenidconfig.enableOnBetPlacement && this.nativeAppService.applicationName === nativeAppValue && (loginHint || idTokenHint);
    }

    private get userIsComingFromPortalPromotions(): boolean {
        const referrer = this.windowRef.nativeWindow.document.referrer;

        return referrer.indexOf('promotions') > -1;
    }
}
