import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { Fixture } from '@cds/betting-offer';
import { RouteTag, SportConstant, usePreloaderData } from '@frontend/sports/common/base-utils';
import { ApiService } from '@frontend/sports/common/core/feature/http';
import {
    LayoutSize,
    Widget,
    WidgetContext,
    WidgetLayoutResponse,
    WidgetPage,
    WidgetResponse,
    WidgetType,
} from '@frontend/sports/types/components/widget';
import { ComposableWidgetPayload } from '@frontend/sports/types/components/widget/types';
import { MediaQueryService } from '@frontend/vanilla/core';
import { includes, merge, uniqBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { SchemaMarkupService } from '../../schema-markup/schema-markup.service';
import { LARGE_LAYOUT_BREAKPOINT, MEDIUM_LAYOUT_BREAKPOINT, SMALL_LAYOUT_BREAKPOINT } from '../core/widget.constants';
import { WidgetContextEnhanceService } from './widget-context-enhance.service';

@Injectable({ providedIn: 'root' })
export class WidgetContextService {
    private readonly sizes = new Map<string, LayoutSize>([
        [LARGE_LAYOUT_BREAKPOINT, LayoutSize.Large],
        [MEDIUM_LAYOUT_BREAKPOINT, LayoutSize.Medium],
        [SMALL_LAYOUT_BREAKPOINT, LayoutSize.Small],
    ]);

    private readonly pages = new Map<RouteTag, WidgetPage>([
        [RouteTag.Lobby, WidgetPage.HomeLobby],
        [RouteTag.SportLobby, WidgetPage.SportLobby],
        [RouteTag.EsportsLobby, WidgetPage.ESportsLobby],
        [RouteTag.Competition, WidgetPage.CompetitionLobby],
        [RouteTag.EventDetails, WidgetPage.EventDetailsPage],
        [RouteTag.MultiSportsLobby, WidgetPage.MultiSportsLobby],
    ]);

    private readonly widgetsWithFixturePayload = [WidgetType.NextToGo, WidgetType.TabbedGrid, WidgetType.OutrightsGrid];

    constructor(
        private api: ApiService,
        private media: MediaQueryService,
        private router: Router,
        private enhanceService: WidgetContextEnhanceService,
        private schemaMarkupService: SchemaMarkupService,
    ) {}

    // TODO Angular 14 update: Setting type for the context parameter is breaking the tests. Investigation needed.
    @usePreloaderData({
        preloader: (<any>window).MS2JS?.ModularHomePageDataLoader,
        condition: (context: any) => {
            if (context.page === WidgetPage.HomeLobby) {
                return true;
            } else if (context.page === WidgetPage.SportLobby) {
                const preloader = (<any>window).MS2JS?.ModularHomePageDataLoader;

                return (
                    preloader.requestContext() &&
                    context.sportId === preloader.requestContext().sportId &&
                    context.layoutSize === preloader.requestContext().layoutSize &&
                    Object.keys(context).filter((k) => context[k] != null).length === 3
                );
            }

            return false;
        },
    })
    getLayout(context: any, enchanceContext: boolean = true): Observable<WidgetLayoutResponse> {
        if (enchanceContext) {
            return this.enhanceContext(context).pipe(switchMap((enhanced) => this.api.get<WidgetLayoutResponse>('widget', enhanced)));
        } else {
            return this.api.get<WidgetLayoutResponse>('widget', context);
        }
    }

    getWidget<T = unknown>(context: Partial<WidgetContext>, addSchemaMarkup = false, forceBatchCall = false): Observable<Widget<T>> {
        if (!context.shouldIncludePayload || forceBatchCall) {
            return this.getWidgetBatch([context]).pipe(map((widgets) => widgets?.[0] as Widget<T>));
        } else {
            if (context.shouldIncludePersonalizedData) {
                return this.getPersonalizedWidgetData(context, addSchemaMarkup);
            }
            return this.getWidgetData(context, addSchemaMarkup);
        }
    }

    getWidgetBatch(contexts: Partial<WidgetContext>[]): Observable<Widget<unknown>[] | undefined> {
        const params = this.getContext();

        return this.enhanceContext(params).pipe(
            switchMap((enhanced) => {
                const data = contexts.map((context) => merge({}, enhanced, context));

                return this.api.post<WidgetResponse>('widget/batch', data, { params: enhanced });
            }),
            map((collection) => collection.widgets),
        );
    }

    private updateSchemaMarkUpJson(widget: Widget<unknown>): void {
        const widgetsToConsider =
            widget.type === WidgetType.Composable
                ? (widget.payload as ComposableWidgetPayload)?.items?.flatMap(
                      (slot) => slot?.activeChildren?.filter((w) => includes(this.widgetsWithFixturePayload, w?.type)) ?? [],
                  )
                : [widget];

        const payload = uniqBy(
            widgetsToConsider.flatMap((w) => (w.payload as { fixtures: Fixture[] }).fixtures),
            (f) => f.id,
        );

        if (payload?.length) {
            this.schemaMarkupService.updateModuleEvents(payload);
        }
    }

    private getWidgetData<T = unknown>(context: Partial<WidgetContext>, addSchemaMarkup: boolean): Observable<Widget<T>> {
        const params = this.getContext();

        return this.enhanceContext(params).pipe(
            switchMap((enhanced) => {
                const data = merge({}, enhanced, context);

                return this.api.get<WidgetResponse>('widget/widgetdata', data);
            }),
            map((collection) => (collection.widgets?.length ? collection.widgets[0] : {}) as Widget<T>),
            tap((widget) => {
                if (addSchemaMarkup && widget.payload) {
                    this.updateSchemaMarkUpJson(widget);
                }
            }),
        );
    }

    private getPersonalizedWidgetData<T = unknown>(context: Partial<WidgetContext>, addSchemaMarkup: boolean): Observable<Widget<T>> {
        const params = this.getContext();

        return this.enhanceContext(params).pipe(
            switchMap((enhanced) => {
                const data = merge({}, enhanced, context);

                return this.api.get<WidgetResponse>('widget/personalizedWidgetData', data);
            }),
            map((collection) => (collection.widgets?.length ? collection.widgets[0] : {}) as Widget<T>),
            tap((widget) => {
                if (addSchemaMarkup && widget.payload) {
                    this.updateSchemaMarkUpJson(widget);
                }
            }),
        );
    }

    getContext({ snapshot }: { snapshot?: ActivatedRouteSnapshot } = {}): WidgetContext {
        snapshot = snapshot || this.getRoute();

        const isTennis = snapshot.params.sport && snapshot.params.sport === SportConstant.Tennis;
        const isFakeRegion = snapshot.params.region === '10001' && snapshot.params.league;

        return {
            layoutSize: this.getSize(),
            page: this.getPage(snapshot.data.tag),
            sportId: snapshot.params.sport ? parseInt(snapshot.params.sport) : undefined,
            regionId: snapshot.params.region && !isTennis && !isFakeRegion ? parseInt(snapshot.params.region) : undefined,
            tournamentId: snapshot.params.region && isTennis ? parseInt(snapshot.params.region) : undefined,
            competitionId: !snapshot.params.isVirtual && snapshot.params.league ? parseInt(snapshot.params.league) : undefined,
            virtualCompetitionId: snapshot.params.isVirtual && snapshot.params.league ? parseInt(snapshot.params.league) : undefined,
            virtualCompetitionGroupId:
                snapshot.params.isVirtual && snapshot.params.virtualCompetitionGroup ? parseInt(snapshot.params.virtualCompetitionGroup) : undefined,
            fixtureId: snapshot.params.event ? snapshot.params.event : undefined,
            fixtureStage: snapshot.params.fixtureStage ? snapshot.params.fixtureStage : undefined,
        };
    }

    enhanceContext(context: WidgetContext): Observable<WidgetContext> {
        return this.enhanceService.getEnhancedProperties(context).pipe(
            map((additionalProps) => {
                return {
                    ...context,
                    ...additionalProps,
                };
            }),
        );
    }

    private getPage(tag: RouteTag): WidgetPage {
        const page = this.pages.get(tag);

        if (page) {
            return page;
        }

        throw new Error('Page not supported');
    }

    private getSize(): LayoutSize {
        for (const [query, size] of this.sizes) {
            if (this.media.isActive(query)) {
                return size;
            }
        }

        throw new Error('Layout configuration not supported');
    }

    private getRoute(): ActivatedRouteSnapshot {
        let route = this.router.routerState.snapshot.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        return route;
    }
}
