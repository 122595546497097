import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConnectionConfig, CustomerDnaBettingOfferConfig } from '@frontend/sports/common/client-config-data-access';
import { ApiService } from '@frontend/sports/common/core/feature/http';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { encodeParams, urljoin } from '../common/url.utils';
import { CustomerDnaProfileProviderService } from '../customer-dna/customer-dna-profile-provider.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { ApiBaseSettingsProvider } from './api-settings.service';
import { CdsRequestOptions } from './cds-request-options';

export interface CdsApiOptions {
    endpoint: string;
}

interface CdsApiContextQueryStringParameters {
    'x-bwin-accessid': string;
    'lang': string;
    'country': string;
    'userCountry': string;
    'userLocation': string;
    'cdnaProfileId'?: string;
    'restrictedCompetitionId'?: number;
    'subdivision'?: string;
    'shopTier'?: number;
}

@Injectable()
export class BaseCdsApi {
    private baseUrl: string;
    private configured = false;
    private options: CdsApiOptions;
    private readonly logger: SportsRemoteLogger;

    constructor(
        private apiService: ApiService,
        loggerFactory: LoggerFactory,
        connectionConfig: ConnectionConfig,
        private apiBaseSettings: ApiBaseSettingsProvider,
        private customerDnaProfileService: CustomerDnaProfileProviderService,
        private customerDnaBettingOfferConfig: CustomerDnaBettingOfferConfig,
    ) {
        this.baseUrl = connectionConfig.cdsUrlBase;
        this.options = <CdsApiOptions>{};
        this.logger = loggerFactory.getLogger('BaseCdsApi');
    }

    configure(options: CdsApiOptions): BaseCdsApi {
        this.configured = true;
        this.options = { ...this.options, ...options };

        return this;
    }

    post<T, K = any>(resource: string, data?: K, options?: CdsRequestOptions): Observable<T | undefined> {
        if (!this.configured) {
            return of(undefined);
        }

        return this.prepareRequestUrl(resource, options).pipe(
            concatMap((url) => {
                return this.apiService.post<HttpResponse<T>>(url, data, { cache: false, resolveWithFullResponse: true }).pipe(
                    map((response) => response.body as T),
                    catchError((err: HttpErrorResponse) => {
                        if (err && err.status > 0) {
                            this.logger.error(err, `Post call to CDS endpoint: ${this.getUrl(resource)} failed`);
                        }

                        return of(undefined);
                    }),
                );
            }),
        );
    }

    get<T, K = any>(resource: string, data?: K, options?: CdsRequestOptions): Observable<T | undefined> {
        if (!this.configured) {
            return of(undefined);
        }

        return this.prepareRequestUrl(resource, options).pipe(
            concatMap((url) => {
                return this.apiService.get<HttpResponse<T>>(url, data, { resolveWithFullResponse: true }).pipe(
                    map((response) => response.body as T),
                    catchError((err: HttpErrorResponse) => {
                        if (err && err.status > 0) {
                            this.logger.error(err, `Get call to CDS endpoint: ${this.getUrl(resource)} failed`);
                        }

                        return of(undefined); // Ventsi: I don't like the to return undefined on error it should be throwError but return to the prev behavior
                    }),
                );
            }),
        );
    }

    private prepareRequestUrl(resource: string, options?: CdsRequestOptions): Observable<string> {
        return this.getDefault(options).pipe(
            map((request) => {
                let url = this.getUrl(resource);
                if (url.indexOf('?') === -1) {
                    url += '?';
                } else {
                    url += '&';
                }

                return url + encodeParams(request);
            }),
        );
    }

    private getDefault(options?: CdsRequestOptions): Observable<CdsApiContextQueryStringParameters> {
        return this.apiBaseSettings.getSettingsWithGeolocation().pipe(
            map((settings) => {
                const { accessId, userLocation, ...rest } = settings;
                const defaultQueryStringParams: CdsApiContextQueryStringParameters = {
                    'x-bwin-accessid': accessId,
                    ...rest,
                    'userLocation': userLocation || '',
                };

                if (this.customerDnaBettingOfferConfig.isEnabled && options?.addCustomerDnaProfileId) {
                    const customerDnaProfileId = this.customerDnaProfileService.getProfileId();

                    if (customerDnaProfileId) {
                        defaultQueryStringParams.cdnaProfileId = customerDnaProfileId;
                    }
                }

                if (options?.restrictedCompetitionId) {
                    defaultQueryStringParams.restrictedCompetitionId = options.restrictedCompetitionId;
                }

                return defaultQueryStringParams;
            }),
        );
    }

    private getUrl(resource: string): string {
        return urljoin(this.baseUrl, this.options.endpoint, resource);
    }
}
