import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { FixtureBase, FixtureType } from '@cds/betting-offer';
import { DayOfRaceDate } from '@cds/betting-offer/domain-specific/horse-racing';
import { Nullable, SportConstant, translationToStr } from '@frontend/sports/common/base-utils';
import { AZSportsDataModel, PrettyUrlsConfig, SeoConfig, Sitecore, SportsConfig } from '@frontend/sports/common/client-config-data-access';
import { DeviceService, Page, QuerySearchParams, UrlService } from '@frontend/vanilla/core';
import { includes, keys, toNumber, values } from 'lodash-es';

import { CategoryType } from '../navigation/navigation.models';
import { SegmentNormalizer, UrlBuilder, UrlParam, UrlSegmentParam } from './url-builder';

export enum LiveUrlParam {
    Highlights = 0,
    SportList = -1,
    Video = -2,
    Favourites = -3,
    Sport = 1,
    Esports = -4,
}

export enum RacingUrlParam {
    Today = 'today',
    Tomorrow = 'tomorrow',
    Future = 'future',
    TodaysResults = 'todays-results',
    NextRaces = 'nextRaces',
}

export enum SportUrlParam {
    Competitions = 'competitions',
    Calendar = 'calendar',
    Today = 'today',
    Betting = 'betting',
    Antepost = 'antepost',
    Featured = 'featured',
    TeamPages = 'teamPages',
    WorldCupHub = 'worldCupHub',
}

export enum SettingsUrlParam {
    Betslip = 'betslip',
    QuickBet = 'quickBet',
    EarlyPayout = 'earlyPayout',
    OddsDisplay = 'oddsDisplay',
    StakeChanges = 'stakeChanges',
}

export enum ModelPopupTypes {
    MyBets = 'my-bets',
    Betslip = 'betslip',
}

export enum VirtualUrlParam {
    OnDemand = 'onDemand',
}

export enum CalendarUrlParam {
    In30minutes = 'in30minutes',
    In60minutes = 'in60minutes',
    In180minutes = 'in180minutes',
    Today = 'today',
    Tomorrow = 'tomorrow',
    After2days = 'after2days',
    After3days = 'after3days',
    Next2days = 'next2days',
    Next3days = 'next3days',
    Next5days = 'next5days',
    MidWeek = 'midWeek',
    ThisWeekend = 'thisWeekend',
}

export enum MyBetsUrlParam {
    Open = 'open',
    Settled = 'settled',
}

export enum CouponUrlParam {
    BetBuilder = 'bet-builder',
}

export enum MarketUrlParam {
    MyBets = 'my-bets',
    BetBuilder = 'bet-builder',
    Sgp = 'SGP',
}

export enum MarketOfferUrlParam {
    NonGridable = 'non-gridable',
    Gridable = 'matches',
    Outrights = 'outrights',
    Specials = 'specials',
    Standings = 'standings',
    Teams = 'teams',
    PriceBoost = 'priceboost',
}

export enum PriceBoostUrlParam {
    PriceBoostHub = 'priceBoost',
}

export enum FavouritesDashboardUrlParam {
    Upcoming = 'upcoming',
    Competitions = 'competitions',
    Players = 'players',
    Events = 'events',
}

export interface GameUrlParam {
    game: string;
    section: string;
    title?: string;
    provider?: string;
    lobbyPosition?: string;
    position?: number;
}

export interface LiveSportUrlOptions {
    sport: UrlParam;
    region?: UrlParam | LiveUrlParam.Sport;
    league?: UrlParam;
    conference?: UrlParam;
    isEsport?: boolean;
}

export interface BetsUrlOptions {
    sport: UrlParam;
    region?: UrlParam;
    league?: UrlParam;
    conference?: UrlParam;
    category?: string;
    subCategory?: Record<CategoryType, string | undefined>;
    dynamicCategory?: string;
}

@Injectable({ providedIn: 'root' })
export class UrlHelperService {
    constructor(
        protected page: Page,
        private location: Location,
        private urlService: UrlService,
        protected urlConfig: PrettyUrlsConfig,
        private sportConfig: SportsConfig,
        protected sitecore: Sitecore,
        private device: DeviceService,
        private seoConfig: SeoConfig,
        private azSportsData: AZSportsDataModel,
    ) {}

    getUrl(url?: string): string {
        if (!url) {
            return '#';
        }

        if (url.indexOf('http') === 0) {
            return url;
        }

        const firstChar = url.charAt(0);
        const locationPath = this.location.path();

        if (url === '/') {
            url = this.prependPrefix('');
        } else if (firstChar === '/') {
            url = this.prependPrefix(url);
        } else if (firstChar === '?') {
            url = `${locationPath}${url}`;
        } else {
            url = `${locationPath}/${url}`;
        }

        return url.replace(/\/$/, '');
    }

    getHomeUrl(): string {
        return `${this.prefix}`;
    }

    getMultiSportsHubUrl(): string {
        return `${this.prefix}/${this.urlConfig.translations.multiSports}/${this.urlConfig.translations.multiSportsHubName}`;
    }

    getESportsUrl(): string {
        return `/${this.page.lang}/${this.esportsTranslation}`;
    }

    getSearchUrl(): string {
        return `${this.prefix}` + '?popup=betfinder';
    }

    getMyBetPopupUrl(): string {
        return `${this.prefix}` + '?popup=my-bets';
    }

    getAtoZUrl(): string {
        return `/${this.page.lang}/product-menu`;
    }

    getCalendarUrl(interval?: string | CalendarUrlParam): string {
        if (!interval) {
            return this.getBuilder(this.urlConfig.translations.calendar).toString();
        }

        const allowed: string[] = values(CalendarUrlParam);
        const allowedTranslations = allowed.map((key) => this.urlConfig.translations[key]);

        if (interval in CalendarUrlParam) {
            return this.getBuilder(this.urlConfig.translations[interval]).toString();
        }

        if (allowed.indexOf(interval) !== -1) {
            return this.getBuilder(this.urlConfig.translations[interval]).toString();
        }
        if (allowedTranslations.indexOf(interval) !== -1) {
            return this.getBuilder(interval).toString();
        } else {
            throw new Error('Wrong calendar interval');
        }
    }

    getLiveUrl(tab: LiveUrlParam = LiveUrlParam.Highlights, tracking?: string): string {
        const map = {
            [LiveUrlParam.Video]: this.urlConfig.translations.video,
            [LiveUrlParam.SportList]: this.urlConfig.translations.all,
            [LiveUrlParam.Highlights]: this.urlConfig.translations.betting,
            [LiveUrlParam.Favourites]: this.urlConfig.translations.favourites,
            [LiveUrlParam.Esports]: this.urlConfig.translations.esports,
        };

        const builder = this.getBuilder(this.urlConfig.translations.live, map[tab]);

        if (tracking) {
            builder.appendQuery({ trid: tracking });
        }

        return builder.toString();
    }

    getPriceBoostUrl(): string {
        return this.getBuilder(this.urlConfig.translations.priceBoost).toString();
    }

    getLiveSportUrl({ sport, region, league, isEsport, conference }: LiveSportUrlOptions): string {
        if (isEsport) {
            return this.getLiveUrl(LiveUrlParam.Esports);
        }

        const builder = this.getBuilder(this.urlConfig.translations.live, sport);

        if (region) {
            if (region === LiveUrlParam.Sport) {
                builder.appendQuery({ fallback: false });
            } else {
                builder.append(region);
            }
        }

        if (league) {
            builder.append(league);
        }

        if (conference) {
            builder.append(conference);
        }

        return builder.toString();
    }

    getVirtualSportUrl(param?: VirtualUrlParam.OnDemand): string;
    getVirtualSportUrl(sport: UrlParam, competition?: UrlParam, event?: string): string;
    getVirtualSportUrl(sportOrParam?: UrlParam | VirtualUrlParam.OnDemand, competition?: UrlParam, event?: string): string {
        const builder = this.getBuilder(this.urlConfig.translations.virtual);

        if (sportOrParam) {
            if (sportOrParam === VirtualUrlParam.OnDemand) {
                builder.append(this.urlConfig.translations.onDemand);
            } else {
                builder.append(sportOrParam);
            }
        }

        if (competition) {
            builder.append(competition);

            if (event) {
                builder.appendQuery({ tab: event });
            }
        }

        return builder.toString();
    }

    getTournamentUrl(sport: UrlParam, tournament: string, league?: UrlParam): string {
        const builder = this.getBuilder(sport, this.urlConfig.translations.tournaments, tournament);

        if (league) {
            builder.append(league);
        }

        return builder.toString();
    }

    getEventUrl(event?: UrlParam, market?: MarketUrlParam): string {
        const builder = this.getBuilder(this.urlConfig.translations.events);

        if (!event) {
            return builder.toString();
        }

        return builder.append(event).appendQuery({ market }).toString();
    }

    getFixtureUrl(sport: UrlParam, fixture: UrlParam, prefix?: string): string {
        return prefix
            ? this.getBuilder(prefix, sport, this.urlConfig.translations.fixtures, fixture).toString()
            : this.getBuilder(sport, this.urlConfig.translations.fixtures, fixture).toString();
    }

    getFixtureUrlByFixture(fixtureLike: Pick<FixtureBase, 'id' | 'name' | 'sport' | 'fixtureType'>, prefix?: string): string {
        const fixture: UrlParam = {
            id: fixtureLike.id,
            name: translationToStr(fixtureLike.name),
        };
        const sport: UrlParam = {
            id: fixtureLike.sport.id,
            name: translationToStr(fixtureLike.sport.name),
        };
        switch (fixtureLike.fixtureType) {
            case FixtureType.Antepost:
                return prefix
                    ? this.getBuilder(prefix, sport, this.urlConfig.translations.fixtures, fixture).toString()
                    : this.getBuilder(sport, this.urlConfig.translations.fixtures, fixture).toString();
            case FixtureType.DayOfRace:
                const dayOfRaceFixture = fixtureLike as Pick<FixtureBase, 'id' | 'meeting'>;

                return this.getRacingUrlByFixture(fixtureLike.sport.id, dayOfRaceFixture);
            default:
                return '';
        }
    }

    getCouponUrl(sport: UrlParam, coupon?: UrlParam | CouponUrlParam): string {
        const builder = this.getBuilder(sport, this.urlConfig.translations.coupons);

        if (coupon) {
            if (coupon === CouponUrlParam.BetBuilder) {
                builder.append(this.urlConfig.translations.betBuilder);
            } else {
                builder.append(coupon);
            }
        }

        return builder.toString();
    }

    getCompetitionCouponUrl(sport: UrlParam, coupon?: UrlParam | CouponUrlParam, region?: UrlParam, league?: UrlParam): string {
        const builder = this.getBuilder(sport, this.urlConfig.translations.coupons);
        if (region) {
            builder.append(region);
        }

        if (league) {
            builder.append(league);
        }

        if (coupon) {
            if (coupon === CouponUrlParam.BetBuilder) {
                builder.append(this.urlConfig.translations.betBuilder);
            } else {
                builder.append(coupon);
            }
        }

        return builder.toString();
    }

    getMultiBuilderUrl(league?: UrlParam): string {
        const builder = this.getBuilder(this.urlConfig.translations.multiBuilder);

        if (league) {
            builder.append(league);
        }

        return builder.toString();
    }

    getBetsUrl({ sport, region, league, conference, category, subCategory, dynamicCategory }: BetsUrlOptions): string {
        const builder = this.getBuilder(this.urlConfig.translations.bets).append(sport).appendIf(region).appendIf(league).appendIf(conference);

        if (category) {
            builder.appendQuery({ category });
        }
        if (subCategory?.outrightCategory || subCategory?.specialCategory) {
            builder.appendQuery(subCategory);
        }

        if (dynamicCategory) {
            builder.appendQuery({ dynamicCategory });
        }

        return builder.toString();
    }

    getBettingUrl({ sport, region, league, conference }: BetsUrlOptions): string {
        const builder = this.getBuilder(sport);

        if (region) {
            builder.append(this.urlConfig.translations.betting).append(region).appendIf(league).appendIf(conference);
        }

        return builder.toString();
    }

    getStandingsUrl(sport: UrlParam, region?: UrlParam, league?: UrlParam, group?: UrlParam): string {
        const builder = this.getBuilder(this.urlConfig.translations.standings);
        builder.append(sport);
        if (region) {
            builder.append(region);
        }
        if (league) {
            builder.append(league);
        }
        if (group) {
            builder.append(group);
        }

        return builder.toString();
    }

    getTeamPagesUrl(sport: UrlParam, team?: UrlParam, league?: UrlParam): string {
        const builder = this.getBuilder(sport, this.urlConfig.translations.teamPages);
        builder.append(league || this.urlConfig.translations.myTeams);
        if (team) {
            builder.append(team);
        }

        return builder.toString();
    }

    getTeamPagesSearchUrl(sportUrl: string, sportId: string | number | undefined): string {
        return sportUrl + `&teamId=${sportId}`;
    }

    getSettingsUrl(settings: SettingsUrlParam): string {
        return this.getBuilder(this.urlConfig.translations.settings, this.urlConfig.translations[settings]).toString();
    }

    getMyBetsUrl(page?: MyBetsUrlParam, params?: Params): string {
        const builder = this.getMyBetsUrlBuilder(page);
        if (params) {
            builder.appendQuery(params);
        }

        return builder.toString();
    }

    getPrintUrlBuilder(): UrlBuilder {
        const builder = this.getMyBetsUrlBuilder();
        builder.append('print');

        return builder;
    }

    shouldRemoveHref(): Nullable<boolean> {
        if (this.seoConfig.removeHrefToEventForRobot && this.device.isRobot) {
            return true;
        }

        return null;
    }

    private getMyBetsUrlBuilder(page?: MyBetsUrlParam): UrlBuilder {
        const builder = this.getBuilder(this.urlConfig.translations.myBets);

        if (page) {
            builder.append(this.urlConfig.translations[page]);
        }

        return builder;
    }

    /**
     * Used to generate sport pages specific urls
     * - sport list url (with fallback from SportsConfig.competitionLandingPage)
     * - sport lobby url (with fallback from SportsConfig.competitionLandingPage)
     * - sport competition page with specific context (all fixtures for that context)
     * - sport comeptition list for specific context (all, today, ....)
     * - sport specific pages (calendar, force lobby)
     *
     * @param {UrlParam} sport sport for which we want to generate the url, if undefined returns all sports url
     * @param {string | SportUrlParam | CalendarUrlParam} context context for which we want to generate the url for, can be a translated
     * token (from PrettyUrlsConfig.translations) or a SportUrl param, and the value will be tested agains the allowed walues, otherwise
     * serice throws. SportUrlParam.Featured which is being used only to force sports with compeittion landing page to
     * generate sport featured url and the context is not used in the url
     * @param {SportUrlParam.Competitions} subContext the sub-context for the url generation, only allowed way is SportUrlParam.Competitions
     * and when used will generate urls for competitions list for specific context
     * @returns {string} url for the requested params
     * @throws when specified context is not a valid token
     * @example
     * // all the examples suppose that the language is de and golf is configured to have competition landing page
     *
     * // returns /de/sports/alle
     * urlHelper.getSportUrl();
     *
     * // returns /de/sports/fußball-4
     * urlHelper.getSportUrl(football);
     * urlHelper.getSportUrl(football, SportUrlParam.Featured);
     *
     * // returns /de/sports/golf-13?fallback=true
     * urlHelper.getSportUrl(golf);
     *
     * // returns /de/sports/golf-13
     * urlHelper.getSportUrl(golf, SportUrlParam.Featured);
     *
     * // returns /de/sports/fußball-4/nach-2-tagen
     * urlHelper.getSportUrl(football, CalendarUrlParam.After3day);
     * urlHelper.getSportUrl(football, 'nach-2-tagen');
     *
     * // returns /de/sports/fußball-4/wetten/wettbewerben
     * urlHelper.getSportUrl(football, SportUrlParam.Betting, SportUrlParam.Competitions);
     *
     * // returns /de/sports/fußball-4/kalendar
     * urlHelper.getSportUrl(football, SportUrlParam.Calendar);
     */
    getSportUrl(sport?: UrlParam, context?: string | SportUrlParam | CalendarUrlParam, subContext?: SportUrlParam.Competitions): string;
    /**
     * Used to generate sport fixture list pages with different contexts and levels
     *
     * @param {UrlParam} sport sport for which we want to generate the url
     * @param {string | SportUrlParam | CalendarUrlParam} context context for which we want to generate the url for, can be a translated
     * token (from PrettyUrlsConfig.translations) or a SportUrl param, and the value will be tested agains the allowed walues, otherwise
     * serice throws.
     * @param {UrlParam} region the region for which we want the fixture list to be filtered
     * @param {UrlParam} league the league (or virtual competition) for which we want the fixture list to be filtered
     * @param {UrlParam} group the group (virtual competition group) or conference for which we want the fixture list to be filtered
     * @param {tab} tab the tab of the specific market offer (matches/outrights/specials) for which we want the fixture list to be filtered
     * @param {shouldApplyFallback} shouldApplyFallback should {sportId}?fallback=true to be applied for special sports
     * @returns {string} url for the requested params
     * @throws when specified context is not a valid token
     * @example
     * // returns /de/sports/fußball-4/nach-2-tagen
     * urlHelper.getSportUrl(football, CalendarUrlParam.After3day);
     * urlHelper.getSportUrl(football, 'nach-2-tagen');
     *
     * // returns /de/sports/fußball-4/wetten/welt-6
     * urlHelper.getSportUrl(football, SportUrlParam.Betting, world);
     *
     * // returns /de/sports/fußball-4/wetten/welt-6/bundesliga-43
     * urlHelper.getSportUrl(football, SportUrlParam.Betting, world, bundesliga);
     *
     * // returns /de/sports/fußball-4/wetten/welt-6/bundesliga-43
     * urlHelper.getSportUrl(football, SportUrlParam.Betting, world, bundesliga);
     *
     * // returns /de/sports/fußball-4/wetten/welt-6/weltmeisterschaft-100
     * urlHelper.getSportUrl(football, SportUrlParam.Betting, world, worldCup);
     *
     * // returns /de/sports/fußball-4/wetten/welt-6/weltmeisterschaft-100/gruppe-a-1
     * urlHelper.getSportUrl(football, SportUrlParam.Betting, world, worldCup, groupA);
     *
     * // returns /de/sports/fußball-4/wetten/welt-6/weltmeisterschaft-100/gruppe-a-1?tab=outrights
     * urlHelper.getSportUrl(football, SportUrlParam.Betting, world, worldCup, groupA, 'outrights');
     */
    getSportUrl(
        sport?: UrlParam,
        context?: string | SportUrlParam | CalendarUrlParam,
        region?: UrlParam,
        league?: UrlParam,
        group?: UrlParam,
        tab?: string,
        shouldApplyFallback?: boolean,
    ): string;

    getSportUrl(
        sport?: UrlParam,
        context?: string,
        regionOrSubContext?: UrlParam | string,
        league?: UrlParam,
        group?: UrlParam,
        tab?: string,
        shouldApplyFallback: boolean = true,
    ): string {
        if (!sport) {
            return this.getBuilder(this.urlConfig.translations.all).toString();
        }

        const builder = this.getSportBuilder(sport);
        // we need to bypass the additional checks so we use the Featured context on the caller
        if (context === SportUrlParam.Featured || (!context && this.device.isRobot)) {
            return builder.toString();
        }

        if (!context) {
            const fallback = shouldApplyFallback && includes(this.sportConfig.competitionLandingPage, toNumber(sport.id));
            if (!this.azSportsData.azSports.find((item) => item.activeName === sport.name)) {
                return builder.appendQuery({ fallback: fallback || undefined }).toString();
            } else if (this.azSportsData.azSports.length) {
                return this.azSportsData.azSports.filter((item) => item.activeName === sport.name)[0].link;
            }
        }

        const appendContext = () => {
            if (keys(this.urlConfig.translations).some((current) => current === context)) {
                // check if context is a non translated token
                builder.append(this.urlConfig.translations[context!]);
            } else if (values(this.urlConfig.translations).some((current) => current === context)) {
                // check if context is a translated token
                builder.append(context!);
            } else {
                throw new Error('Context is not valid value');
            }

            return builder;
        };

        if (regionOrSubContext === SportUrlParam.Competitions) {
            appendContext().append(this.urlConfig.translations.competitions);
        } else {
            if (sport.id === SportConstant.Horses || sport.id === SportConstant.Greyhounds) {
                throw new Error(`Context is not valid for ${sport.id === SportConstant.Horses ? 'horse' : 'greyhounds'} racing`);
            } else if (context === SportUrlParam.Antepost) {
                return builder.append(this.urlConfig.translations.eachWay).toString();
            } else {
                appendContext();
            }

            if (regionOrSubContext) {
                builder.append(regionOrSubContext);

                if (league) {
                    builder.append(league);

                    if (group) {
                        builder.append(group);
                    }
                }
            }
        }

        if (tab) {
            builder.appendQuery({ tab });
        }

        return builder.toString();
    }

    getGameUrl(gameUrlParam: GameUrlParam): string {
        const builder = this.getCaseSensitiveBuilder(this.urlConfig.translations.games, gameUrlParam.game, gameUrlParam.section);

        if (gameUrlParam.position) {
            builder.append(gameUrlParam.position);
        }
        if (gameUrlParam.title) {
            builder.append(gameUrlParam.title);
        }
        if (gameUrlParam.provider) {
            builder.append(gameUrlParam.provider);
        }
        if (gameUrlParam.lobbyPosition) {
            builder.append(gameUrlParam.lobbyPosition);
        }

        return builder.toString();
    }

    getPopupUrl(popupName: string): string {
        const url = this.urlService.current();
        url.search.set('popup', popupName);

        return url.url();
    }

    getFavouriteUrl(name?: string): string {
        const builder = this.getBuilder(this.urlConfig.translations.favourites);

        if (name) {
            builder.append(name);
        }

        return builder.toString();
    }

    getFavouriteUrlByUrlParam(favouritesUrlParam: FavouritesDashboardUrlParam): string {
        const builder = this.getBuilder(this.urlConfig.translations.favourites);
        const name = this.urlConfig.translations[favouritesUrlParam];
        if (name) {
            builder.append(name);
        }

        return builder.toString();
    }

    getRaceUrl(sportId: SportConstant, param: RacingUrlParam | UrlParam, filterOrRace?: RacingUrlParam | UrlParam): string {
        const sport = {
            id: sportId,
            name:
                sportId === SportConstant.Horses ? this.sitecore.horseRacing.common.HorsesLinkText : this.sitecore.horseRacing.common.GreyhoundRacing,
        };

        const builder = this.getSportBuilder(sport);

        if (
            param === RacingUrlParam.Future ||
            param === RacingUrlParam.Today ||
            param === RacingUrlParam.Tomorrow ||
            param === RacingUrlParam.NextRaces
        ) {
            const url = builder.append(this.urlConfig.translations[param]).toString();

            return url;
        }

        return this.getRacingUrl(param, builder, filterOrRace);
    }

    protected getRacingUrl(param: RacingUrlParam | UrlParam, builder: UrlBuilder, filterOrRace?: RacingUrlParam | UrlParam): string {
        if ('id' in (param as UrlParam)) {
            builder.append(param);
            filterOrRace = filterOrRace || RacingUrlParam.Today;

            for (const filter of [RacingUrlParam.Today, RacingUrlParam.Tomorrow]) {
                if (filterOrRace === filter || filterOrRace === this.urlConfig.translations[filter]) {
                    builder.append(this.urlConfig.translations[filter]);
                }
            }
            if (typeof filterOrRace !== 'string' && 'id' in filterOrRace) {
                builder.append(filterOrRace);
            }

            return builder.toString();
        } else {
            throw new Error('Param is not valid for horse racing');
        }
    }

    getRacingUrlByFixture(
        sportId: SportConstant,
        fixtureLike: Pick<FixtureBase, 'id' | 'meeting'>,
        dayOfRace?: DayOfRaceDate,
        isAllRaceUrl = false,
    ): string {
        const meetingParam = {
            id: fixtureLike.meeting.id,
            name: translationToStr(fixtureLike.meeting.name),
        };

        if (isAllRaceUrl) {
            const dayRoute = (dayOfRace === DayOfRaceDate.Today ? RacingUrlParam.Today : RacingUrlParam.Tomorrow).concat('/all');
            const allRaceParam = {
                id: dayRoute,
                name: dayRoute,
            };

            return this.getRaceUrl(sportId, meetingParam, allRaceParam);
        }

        return this.getRaceUrl(sportId, meetingParam, { id: fixtureLike.id, name: '' });
    }

    getAllFixtuesRacingUrl(sportId: SportConstant, fixtureLike: Pick<FixtureBase, 'id' | 'meeting'>, dayOfRace: DayOfRaceDate): string {
        const meetingParam = {
            id: fixtureLike.meeting.id,
            name: translationToStr(fixtureLike.meeting.name),
        };
        const dayRoute = (dayOfRace === DayOfRaceDate.Today ? RacingUrlParam.Today : RacingUrlParam.Tomorrow).concat('/all');
        const allRaceParam = {
            id: dayRoute,
            name: dayRoute,
        };

        return this.getRaceUrl(sportId, meetingParam, allRaceParam);
    }

    getCaseSensitiveBuilder(...params: UrlSegmentParam[]): UrlBuilder {
        return this.getBuilderInternal(params, true);
    }

    getBuilder(...params: UrlSegmentParam[]): UrlBuilder {
        return this.getBuilderInternal(params);
    }

    protected getBuilderInternal(params: UrlSegmentParam[], caseSensitive: boolean = false): UrlBuilder {
        const normalizer = new SegmentNormalizer(this.urlConfig, caseSensitive);

        const builder = new UrlBuilder(normalizer);

        builder.append(this.page.lang).append(this.page.product);
        params.forEach((param) => builder.append(param));

        return builder;
    }

    protected getSportBuilder(...params: UrlSegmentParam[]): UrlBuilder {
        return this.getBuilder(...params);
    }

    getAbsolute(relativeUrl: string = ''): string {
        const url = this.urlService.current();
        let absUrl = `${url.protocol}://${url.hostname}`;
        if (!relativeUrl) {
            absUrl += `${this.prefix}`;
        }
        absUrl += relativeUrl;

        return absUrl;
    }

    getLastSegment(url: string): string {
        const parsed = this.urlService.parse(url);

        return parsed.pathname.split('/').pop() || parsed.pathname;
    }

    getQueryParams(url?: string): QuerySearchParams | undefined {
        if (url) {
            return this.urlService.parse(url).search;
        }

        return;
    }

    private prependPrefix(url: string): string {
        const isEsports = url.indexOf(this.esportsTranslation) !== -1;
        if (url.indexOf(this.prefix) === -1 && !isEsports) {
            return this.prefix + url;
        }

        return url;
    }

    private get prefix(): string {
        return `/${this.page.lang}/${this.page.product}`;
    }

    private get esportsTranslation(): string {
        return this.urlConfig.translations.esports;
    }

    getCurrentUrlPathName(): string {
        return this.urlService.current().pathname;
    }
}
