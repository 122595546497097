import { NativePrice } from '@frontend/sports/common/odds-lib';
import { MarqueeTile, MarqueeType } from '@frontend/sports/types/components/content';
import { LinkViewModel } from '@frontend/sports/types/models/link';

import { BetBuilderMarket } from '../betbuilder/model/bet-builder.model';
import { Promotion } from '../crm-offer-data/crm-offer.model';
import { EventModel, EventOptionGroup } from '../event-model/model/event.model';

export enum BadgeAndRibbonVisibilityOptions {
    ShowBadgeWithoutTitleOverride = 'ShowBadgeWithoutTitleOverride',
    ShowBadgeWithTitleOverride = 'ShowBadgeWithTitleOverride',
    DoNotShowAnyBadgeOrRibbon = 'DoNotShowAnyBadgeOrRibbon',
    ShowPromoRibbon = 'ShowPromoRibbon',
}

export enum NameVisibility {
    WithoutOverride = 'WithoutOverride',
    WithOverride = 'WithOverride',
    DoNotShow = 'DoNotShow',
}

export enum LobbyType {
    Home,
    Sport,
}

export enum CompetitionPageType {
    Competition,
    Region,
}

export type MarqueeComponentTile = Tile | Promotion;

export interface IMarqueeTile {
    marqueeType: MarqueeType;
    fixtureId: string;
    sportId: number;
    competitionId: number;
    regionId: number;
    showOnHomeLobby: boolean;
    showOnSportsLobby: boolean;
    marketAndOptionNameOverride?: string;
    eventNameOverride?: string;
    badgeTitleOverride?: string;
    previousOdds?: number;
    previousOddsNumerator?: number;
    previousOddsDenominator?: number;
    previousOddsUs?: number;
    gridGroupId: string;
    gameTemplateIds: number[];
    showOnEventPage: boolean;
    eventIds?: string[];
    resultId?: number;
    optionMarketId?: number;
    gameId?: number;
    optionId?: number;
    betbuilderSelectionOverride?: string;
    eventNameVisibility: string;
    marketAndOptionNameVisibility: string;
    link?: LinkViewModel;
    imageUrl: string;
    imageAlt?: string;
    trafficId?: string;
    badgeAndRibbonVisibilityOption: string;
    noEventSportIds?: number[];
    showOnCompetitionPage: boolean;
    competitionIds?: number[];
    regionIds: number[];
    regionSportId: number;
    crmPromotionId?: string;
    crmPromotionVisibility?: string;
    showOnTeamsPage: boolean;
    OGPOverLay?: boolean;
}
export interface Tile {
    index: number;
    source: MarqueeTile | null;
    sportId: number;
    fallbackMarketType?: string;
    fixtureId: string;
    event: EventModel;
    marqueeType: MarqueeType;
    showOnHomeLobby: boolean;
    showOnSportsLobby: boolean;
    crmPromotionId?: string;
    crmPromotionVisibility: VisibilityOnMarquee;
    marketType?: string;
    trackingDetail?: MarqueeTracking;
    isFromPortal?: boolean;
    ogpOverlay?: boolean;
}

export class MarqueeTracking {
    modulePosition: string;
    pageLayout: string;
    moduleCustomName: string;
    marqueeRecommendationType?: string;
    marqueeName?: string;
    sitecoreTemplateId?: string;
}

export interface FixtureTile extends Tile {
    optionId: number;
    optionGroupId: number;
    marketAndOptionNameOverride?: string;
    eventNameOverride?: string;
    badgeTitle?: string;
    previousNativePrice?: NativePrice;
    optionGroup?: EventOptionGroup;
    gridGroupId?: string;
    gridGroupIds: string[];
    gameTemplateIds: string[];
    eventNameVisibility: string;
    marketAndOptionNameVisibility: string;
    link?: LinkViewModel;
    imageUrl?: string;
    imageAlt?: string;
    trafficId?: string;
    badgeAndRibbonVisibilityOption: string;
    noEventSportIds?: number[];
    isPriceBoosted: boolean;
}

export type TileWithFallbackMarketTypes = Tile;

export interface BetBuilderTile extends Tile {
    name: string;
    betBuilderFixtureId: string;
    market: BetBuilderMarket;
    imageUrl?: string;
    hideBuildABetBadge: boolean;
    leftAlignOddsButton: boolean;
    AwayIndicatorOverride?: string;
    BadgeOverrideText?: string;
    OverriddenTeamnames?: string;
    eventOptionGroups?: EventOptionGroup[];
}

export interface MarqueeTitleIcon {
    sportId?: number;
    id: string;
    title: string;
    icon?: string;
}

export interface OutrightTileData {
    id: number;
    index: number;
    odds: number;
    name: string;
}

export enum VisibilityOnMarquee {
    MarqueeOnly = 'MarqueeOnly',
    PromotionOnly = 'PromotionOnly',
    All = 'All',
}

export enum TrackingTileNames {
    RegularMarquee = 'eventMarquee',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BuildABetMarquee = 'BuildABetMarquee',
    SpecialMarquee = 'SpecialMarquee',
}

export type TileOrPromotion = Tile | Promotion;

export function isTile(item: TileOrPromotion): item is Tile {
    return 'marqueeType' in item && item.marqueeType !== undefined;
}

export function isRegularTile(item: Tile): item is FixtureTile {
    return item.marqueeType === MarqueeType.Regular;
}

export function isSpecialTile(item: Tile): item is FixtureTile {
    return item.marqueeType === MarqueeType.Special;
}

export function isBetBuilderTile(item: Tile): item is BetBuilderTile {
    return item.marqueeType === MarqueeType.BuildABet;
}

export interface ScoreDetails {
    position: number;
    score: number;
}

export interface CompetitonTileRequestModel {
    competitionIds: number[];
    sportId: number;
    type: CompetitionPageType;
    interval?: { from?: string; to?: string };
}
