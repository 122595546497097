// Autogenerated by namespace Frontend.Sports.ContractsBuilder

import { Injectable } from '@angular/core';
import { ClientConfig, ClientConfigProductName, ClientConfigService, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';
import { AccaBoostCalculations } from '@frontend/sports/types/components/acca-boost';
import { ProfileActionsSwitch, ProfileConfiguration } from '@frontend/sports/types/components/a-r-c';
import { AZSportsViewModel } from '@frontend/sports/types/components/a-z-menu';
import { GamesBannersConfig, JackpotBannersConfig, VirtualsBannersConfig } from '@frontend/sports/types/components/banners';
import { BetBuilderAmbassador, IframeUrlParams, ScorecastLongIdPrefixes } from '@frontend/sports/types/components/bet-builder';
import { FixtureLimitByMarketType } from '@frontend/sports/types/components/bet-finder';
import { BetslipDisplayMode } from '@frontend/sports/types/components/bet-placement';
import { SportSpecificPitchStyling } from '@frontend/sports/types/components/betradar-visualization';
import { OptionNameMappingConfiguration, OptionNameOverridesViewModel, TranslationsModel } from '@frontend/sports/types/components/bet-recommender';
import { BetslipOddsPreferencesReminderConfiguration } from '@frontend/sports/types/components/betslip-odds-preferences-reminder';
import { BetslipUpsellMarketsGroup } from '@frontend/sports/types/components/betslip-upsell';
import { CalendarModel } from '@frontend/sports/types/components/calendar';
import { LinkTemplateForClient } from '@frontend/sports/types/components/client-content';
import { AutomatedMarqueeTile, AutomatedMarqueeTileImage, BetBuilderCustomMessage, BetBuilderThemedTab, MarqueeTile } from '@frontend/sports/types/components/content';
import { CrmTokenConfig } from '@frontend/sports/types/components/crm-offers';
import { CrmPostBetSignpostingConfiguration } from '@frontend/sports/types/components/crm-post-bet-signposting';
import { CrmPromotionConfiguration } from '@frontend/sports/types/components/crm-promotions';
import { IconInfo } from '@frontend/sports/types/components/event-details';
import { EventDetailsSitemapDictionary } from '@frontend/sports/types/components/event-details/types';
import { DisabledNotificationSport, GreyhoundsRacingEasyBetViewOption, HorseRacingEasyBetViewOption } from '@frontend/sports/types/components/features';
import { CoordinatorSettings, InstallerSettings } from '@frontend/sports/types/components/geolocation';
import { MapMarketToGameTemplateIds, MarqueeGroupsOfMarkets, SixPackGridGroupMapping } from '@frontend/sports/types/components/highlights-marquee';
import { ImageCompressionFormat, SportCompetitionFormat } from '@frontend/sports/types/components/images';
import { HeadlineModel } from '@frontend/sports/types/components/intro-pages';
import { MarketingViewModel } from '@frontend/sports/types/components/marketing';
import { MatchSimulationConfiguration } from '@frontend/sports/types/components/media';
import { MarketGridGroup } from '@frontend/sports/types/components/multi-builder';
import { SportConfiguration } from '@frontend/sports/types/components/native-alert';
import { NextToGoGroupsOfMarkets, NextToGoModule } from '@frontend/sports/types/components/next-to-go';
import { OddsDisplayConfiguration } from '@frontend/sports/types/components/odds-display';
import { ParticipantImageFormat } from '@frontend/sports/types/components/outrights-grid';
import { QuickLinkViewModel } from '@frontend/sports/types/components/quick-links';
import { MarketMappingConfiguration, RandomMultiGeneratorSportsConfig, RandomMultiGeneratorSportsSelection } from '@frontend/sports/types/components/random-multi-generator';
import { BackgroundImage, ShowcaseGroupsOfMarkets, ShowcaseViewModel } from '@frontend/sports/types/components/showcase';
import { BetBuilder, BetBuilderEntainUI, BetBuilderHelpPage, BetFinder, Betslip, BetslipSettings, BetslipUpsell, BetStation, BettingPreferences, CashoutSettings, ContentMessages, Coupons, CouponTags, CrmPostBetSignposting, CrmPromotions, CrmPromotionsBanners, CrmTokens, EarlyPayout, EarlyPayoutErrors, EarlyPayoutSettings, EarlyPayoutV2, ESportsConfig, Event, EventGameNotes, EventGrid, EventGridMarketHeaders, EventScoreboard, FavouritesDashboard, FavouritesMessages, Fixture, FormCoupon, FreeBetOverview, GeoLocation, GlobalMessages, GreyHoundRacing, HorseRacing, LinkExternalAccounts, LiveAnimation, LiveNavigation, LiveSportsVisualizationTranslation, Lobby, MarketHelp, MarketSubtypeLogo, Marquee, Media, Media_NotLoggedin, Menus, ModalDialogs, MultiBuilder, MultiRandomGenerator, MyBets, MyBetsFilterView, MyBetsPrint, NativeAlert, NgrxReports, OddsDisplay, PanicButtonMessages, QuickBet, QuickBetButtonStates, QuickBetHelp, QuickBetMessages, QuickBetModalDialog, QuickBetNumpad, ReBet, ReturnsMessage, Seo, ShareMyBet, ShowcaseMessages, SitecoreAccaBoost, SitecoreStatistics, StakeChanges, SystemBets, TeamGrouping, ToastLiveNotifications, ToastNotifications, Widgets } from '@frontend/sports/types/components/sitecore';
import { SportOfferOrder, VirtualSportsConfiguration } from '@frontend/sports/types/components/sports';
import { AllSportsSection, PopularSection, RecentlyVisitedSection } from '@frontend/sports/types/components/sports-product-menu';
import { CompetitionRecordsFormat, DirectMatchesBarColors, PromotionHighlightColorsWithPositions } from '@frontend/sports/types/components/statistics';
import { TeamPagesViewModel } from '@frontend/sports/types/components/team-pages';
import { TeaserBetPointsSlider, TeaserBetTemplateIds, TeaserPriceTableCacheDuration } from '@frontend/sports/types/components/teaser-bet';
import { Tournament } from '@frontend/sports/types/components/tournaments';
import { PageRefreshConfig, Widget } from '@frontend/sports/types/components/widget';
import { LinkListLayout } from '@frontend/sports/types/components/widget/types';
import { CultureUrl } from '@frontend/sports/types/components/world-cup-event-hub';
import { EventCarouselVisibilityConfig, MarketGroupsWithNewBadge, OptionGroupRowsThreshold, OptionPanelThreshold, OptionTypesToFilterTV2MarketOptions, OverrideParticipantNameOptionMarkets, ScorecastRegexValues, VerticalGridGroupOption } from '@frontend/sports/types/configuration';
import { BogVersion, EventDetailsModel, SecondaryBroadcastInfo, TokenCardDetailsMessages, TokenCardDetailsReplaceParameters, TrapImage } from '@frontend/sports/types/configuration/client-configuration';
import { BetradarImage } from '@frontend/sports/types/models/bet-radar';
import { ConfigSettings } from '@frontend/sports/types/models/config-settings';
import { CRMTokenImage } from '@frontend/sports/types/models/crm-tokens';
import { CdnaUserProfile } from '@frontend/sports/types/models/customer-dna';
import { EarlyPayoutPushErrorItem } from '@frontend/sports/types/models/early-payout';
import { NavigationBarItem, NavigationSportsConfig } from '@frontend/sports/types/models/navigation-bar';
import { PopularSearchModel } from '@frontend/sports/types/models/popular-search';
import { ScoreboardBackground, ScoreboardBackgroundModel } from '@frontend/sports/types/models/scoreboard';
import { OddsDisplayFormat, UserSettingsData } from '@frontend/sports/types/models/user-settings';
import { ClientViewTemplate } from '@frontend/vanilla/content/client/documents';

@ClientConfig({ key:'msAccaBoost' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: accaBoostConfigFactory,
})
export class AccaBoostConfig {
	accaBoostCalculation: AccaBoostCalculations;
	allowUnAuthenticatedUsers: boolean;
	areNotificationsEnabled: boolean;
	errorNotificationDuration: number;
	hideCategoriesInTokenDrawer: boolean;
	icon: string;
	isCompactLadderEnabled: boolean;
	isEnabled: boolean;
	maxRewardNotificationDuration: number;
	notificationDuration: number;
	oneSelectionAwayNotificationDuration: number;
	pollingInterval: number;
	sitecoreTokenDataCacheTime: number;
}

export function accaBoostConfigFactory(service: ClientConfigService): AccaBoostConfig {
	return service.get(AccaBoostConfig);
}

@ClientConfig({ key:'msAccountBalance' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: accountBalanceConfigFactory,
})
export class AccountBalanceConfig {
	retry: number;
	retryDelay: number;
	timeout: number;
}

export function accountBalanceConfigFactory(service: ClientConfigService): AccountBalanceConfig {
	return service.get(AccountBalanceConfig);
}

@LazyClientConfig({ key:'msAnimationBackgrounds' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: animationBackgroundsFactory,
})
export class AnimationBackgrounds extends LazyClientConfigBase {
	backgrounds: ScoreboardBackground[];
}

export function animationBackgroundsFactory(service: LazyClientConfigService): AnimationBackgrounds {
	return service.get(AnimationBackgrounds);
}

@ClientConfig({ key:'msAnimation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: animationConfigFactory,
})
export class AnimationConfig {
	isBasketballAnimationEnabled: boolean;
	isIceHockeyAnimationEnabled: boolean;
	isTennisAnimationEnabled: boolean;
}

export function animationConfigFactory(service: ClientConfigService): AnimationConfig {
	return service.get(AnimationConfig);
}

@ClientConfig({ key:'msApp' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: appConfigFactory,
})
export class AppConfig {
	appName: string;
	lcpFallbackTimeout: number;
	offlineDurationReloadThreshold: number;
	pageHiddenReloadThreshold: number;
	publicAccessId?: string;
	setAccountIdCookie: boolean;
}

export function appConfigFactory(service: ClientConfigService): AppConfig {
	return service.get(AppConfig);
}

@ClientConfig({ key:'msARC' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: aRCConfigFactory,
})
export class ARCConfig {
	isPlayBreakEnabled: boolean;
	isUserProfilingEnabled: boolean;
	pageHiddenReloadThreshold: number;
	profileActionsSwitch: ProfileActionsSwitch;
	profileConfiguration: ProfileConfiguration[];
	showSelfExclusionLock: boolean;
}

export function aRCConfigFactory(service: ClientConfigService): ARCConfig {
	return service.get(ARCConfig);
}

@ClientConfig({ key:'msArcUser' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: arcUserConfigFactory,
})
export class ArcUserConfig {
	arcPlayBreak: boolean;
	arcProfileDetails: ProfileConfiguration;
}

export function arcUserConfigFactory(service: ClientConfigService): ArcUserConfig {
	return service.get(ArcUserConfig);
}

@ClientConfig({ key:'msAutomatedMarqueeTiles' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: automatedMarqueeTilesConfigFactory,
})
export class AutomatedMarqueeTilesConfig {
	automatedMarqueesTileImages: AutomatedMarqueeTileImage[];
	automatedMarqueesTiles: AutomatedMarqueeTile[];
}

export function automatedMarqueeTilesConfigFactory(service: ClientConfigService): AutomatedMarqueeTilesConfig {
	return service.get(AutomatedMarqueeTilesConfig);
}

@ClientConfig({ key:'msAZMenu' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: aZMenuConfigFactory,
})
export class AZMenuConfig {
	removeLetterBreakersInMobile: boolean;
	showAZCustomLinks: boolean;
	showNewBadge: boolean;
}

export function aZMenuConfigFactory(service: ClientConfigService): AZMenuConfig {
	return service.get(AZMenuConfig);
}

@ClientConfig({ key:'msAZSportsData' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: aZSportsDataModelFactory,
})
export class AZSportsDataModel {
	azSports: AZSportsViewModel[];
}

export function aZSportsDataModelFactory(service: ClientConfigService): AZSportsDataModel {
	return service.get(AZSportsDataModel);
}

@ClientConfig({ key:'msBanners' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: bannersConfigFactory,
})
export class BannersConfig {
	appendSessionKey: boolean;
	depositUrl: string;
	desktopCasinoBannerOpenTarget: string;
	enabled: boolean;
	games: GamesBannersConfig;
	isKYCCheckEnabled: boolean;
	jackpot: JackpotBannersConfig;
	keepAliveUrl: string;
	lobbyType: string;
	parentUrl: string;
	responsibleGamingUrl: string;
	virtuals: VirtualsBannersConfig;
}

export function bannersConfigFactory(service: ClientConfigService): BannersConfig {
	return service.get(BannersConfig);
}

@ClientConfig({ key:'msBaseTranslation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: baseTranslationConfigFactory,
})
export class BaseTranslationConfig {
	defaultLanguage: string;
	isEnabled: boolean;
}

export function baseTranslationConfigFactory(service: ClientConfigService): BaseTranslationConfig {
	return service.get(BaseTranslationConfig);
}

@ClientConfig({ key:'msBetBuilder' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betBuilderConfigFactory,
})
export class BetBuilderConfig {
	ambassadors: BetBuilderAmbassador[];
	betFeedbackRetryCount: number;
	betFeedbackRetryDelay: number;
	betGeniusIframeUrlParameters?: {[key: string]: IframeUrlParams;};
	betGeniusWidgetLanguage: string;
	customMessages: {[key: string]: BetBuilderCustomMessage[];};
	defaultSGPExpandedMarketsCount: number;
	defaultSGPOverlayExpandedMarketsCount: number;
	enablePrecreatedBetBuilderTab: boolean;
	hideBetbuilderDrawer: boolean;
	incrementForPrecreatedMarketsShowMore: number;
	initialPrecreatedMarketsPerSport: number;
	isBetBuilderEnabled: boolean;
	isBetBuilderEnabledForSport?: number[];
	isBetBuilderInfoMessageEnabled: boolean;
	isBetBuilderNewBadgeEnabledForSport?: number[];
	isPrecreatedBetBuilderBadgeEnabled: boolean;
	isPrecreatedBetBuilderEnabled: boolean;
	isPrecreatedBetBuilderNewBadgeEnabledForSport?: number[];
	maxSelectionsPerPick: number;
	onboardingMessageEnabledSports?: number[];
	onboardingMessageMaxAppearances: number;
	pageSize: number;
	scorecastLongIdPrefixes?: ScorecastLongIdPrefixes;
	showEntainUiInOverlay: boolean;
	themedTabs: {[key: string]: BetBuilderThemedTab[];};
	useBetBuilderInEntainUI?: number[];
}

export function betBuilderConfigFactory(service: ClientConfigService): BetBuilderConfig {
	return service.get(BetBuilderConfig);
}

@ClientConfig({ key:'msBetContextualTracking' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betContextualTrackingConfigFactory,
})
export class BetContextualTrackingConfig {
	allowedPages: string[];
	isEnabled: boolean;
}

export function betContextualTrackingConfigFactory(service: ClientConfigService): BetContextualTrackingConfig {
	return service.get(BetContextualTrackingConfig);
}

@ClientConfig({ key:'msBetFinder' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betFinderConfigFactory,
})
export class BetFinderConfig {
	fixtureLimitByMarketType: FixtureLimitByMarketType;
	loaderTimeDelay: number;
	maxEventsAmount: number;
	maxNumberOfRecentlySearchItems: number;
	maxRecentSearchCount: number;
	minSearchStringLength: number;
	recentlySearchedExpirationDays: number;
	showLoader: boolean;
}

export function betFinderConfigFactory(service: ClientConfigService): BetFinderConfig {
	return service.get(BetFinderConfig);
}

@ClientConfig({ key:'msBetradarImages' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betradarImagesFactory,
})
export class BetradarImages {
	images: BetradarImage[];
}

export function betradarImagesFactory(service: ClientConfigService): BetradarImages {
	return service.get(BetradarImages);
}

@ClientConfig({ key:'msBetradarVisualization' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betradarVisualizationConfigFactory,
})
export class BetradarVisualizationConfig {
	clientId: string;
	languageMapping: {[key: string]: string;};
	pitchConfiguration: {[key: string]: string;};
	pitchLogoEnabled: number[];
	scriptUrl: string;
	sideLineLogoEnabled: number[];
	sportSpecificPitchConfiguration: {[key: number]: SportSpecificPitchStyling;};
	stickyAddonSupportedSports: number[];
	supportedCoverageLevel: {[key: number]: number;};
	supportedSports: number[];
	teamInvertCompetitions: {[key: number]: number[];};
}

export function betradarVisualizationConfigFactory(service: ClientConfigService): BetradarVisualizationConfig {
	return service.get(BetradarVisualizationConfig);
}

@ClientConfig({ key:'msBetRecommender' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betRecommenderConfigFactory,
})
export class BetRecommenderConfig {
	isEnabled: boolean;
	minimumLoadingDelay: number;
	optionNameMapping: {[key: number]: OptionNameMappingConfiguration[];};
}

export function betRecommenderConfigFactory(service: ClientConfigService): BetRecommenderConfig {
	return service.get(BetRecommenderConfig);
}

@ClientConfig({ key:'msBetRecommenderConfigData' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betRecommenderConfigDataFactory,
})
export class BetRecommenderConfigData {
	optionNameOverrides: OptionNameOverridesViewModel[];
	translations?: TranslationsModel;
}

export function betRecommenderConfigDataFactory(service: ClientConfigService): BetRecommenderConfigData {
	return service.get(BetRecommenderConfigData);
}

@ClientConfig({ key:'msBetslipBar' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betslipBarConfigFactory,
})
export class BetslipBarConfig {
	displayMyBets: boolean;
	hideOnEmptyBetslip: boolean;
	includeStakeInWinnings: boolean;
	isAngstromSGPEnabled: boolean;
	isEnabled: boolean;
}

export function betslipBarConfigFactory(service: ClientConfigService): BetslipBarConfig {
	return service.get(BetslipBarConfig);
}

@ClientConfig({ key:'msBetslip' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betslipConfigFactory,
})
export class BetslipConfig {
	allowBetbuilderCombos: boolean;
	allowClientComboPrevention: boolean;
	allowMixedVirtualsCombo: boolean;
	allowMultipleSingles: boolean;
	betbuilderComboTooltipViewsThreshold: number;
	betslipToasterVisibilityTimespan: number;
	depositSuccessfulVisibilityTimespan: number;
	disableOLTracking: boolean;
	displayMode: BetslipDisplayMode;
	excludeNonCombinableBets: boolean;
	globalErrorVisibilityTimespan: number;
	hideStraightBetDeclined: boolean;
	isAcceptAndPlaceBetEnabled: boolean;
	isBalanceHeaderEnabled: boolean;
	isBankerEnabled: boolean;
	isCloseCtaTextEnabled: boolean;
	isDepositAndPlaceBetEnabled: boolean;
	isDepositOnBetslipConfirmationEnabled: boolean;
	isDoubleBetPreventionEnabled: boolean;
	isEmptyDefaultStakeEnabled: boolean;
	isLineSwitcherEnabled: boolean;
	isLinearCollapsibleModulesEnabled: boolean;
	isLinearKeypadEnabled: boolean;
	isLinearSeparateBetBuilderModuleEnabled: boolean;
	isLinearToggleSwitcherEnabled: boolean;
	isLiveOneClickDepositEnabled: boolean;
	isMinStakeButtonEnabled: boolean;
	isMultiReturnsMessageEnabled: boolean;
	isSportcastAsComboEnabled: boolean;
	isSystemBetEnabled: boolean;
	isSystemMOfNEnabled: boolean;
	linearBetslipTypesOrder: string[];
	linearModeCheckboxesEnabled: boolean;
	maxBetslipBets: number;
	maxComboBets: number;
	maxSystemBets: number;
	maxTeaserBets: number;
	minComboBets: number;
	minSystemBets: number;
	minTeaserBets: number;
	payPalWalletSelectionEnabledForCountries: string[];
	paypalWalletIconClass: {[key: string]: string;};
	paypalWalletUseNewFormula: boolean;
	preventDuplicateRequestOnFrontend: boolean;
	removeAllPicksConfirmationTimeout: number;
	roundPriceValues: boolean;
	roundValues: boolean;
	selectionsGeneralStakeEnabled: boolean;
	showAccountBalanceWithSportsBonus: boolean;
	showBalanceAndDepositOnBetslip: boolean;
	showBalanceOnQuickBet: boolean;
	sportsToHideRegionInfo: number[];
	systemBetsNameType: string;
	taxCalculation: string;
	validation?: {[key: string]: string;};
}

export function betslipConfigFactory(service: ClientConfigService): BetslipConfig {
	return service.get(BetslipConfig);
}

@ClientConfig({ key:'msBetslipOnboarding' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betslipOnboardingConfigFactory,
})
export class BetslipOnboardingConfig {
	comboOnboardingImageUrl?: string;
	systemOnboardingImageUrl?: string;
	teaserOnboardingImageUrl?: string;
}

export function betslipOnboardingConfigFactory(service: ClientConfigService): BetslipOnboardingConfig {
	return service.get(BetslipOnboardingConfig);
}

@ClientConfig({ key:'msBetslipUpsell' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betslipUpsellConfigFactory,
})
export class BetslipUpsellConfig {
	blackListedCountries: string[];
	isBetslipUpsellExpanded: boolean;
	isEnabledForMulti: boolean;
	isEnabledForSingle: boolean;
	marketGridGroupIds: BetslipUpsellMarketsGroup[];
	maxUpsells: number;
	moduleBackgroundImage?: string;
	modulePosition: string;
	overrideDontShow: boolean;
	timeRangeDifferenceValue: number;
}

export function betslipUpsellConfigFactory(service: ClientConfigService): BetslipUpsellConfig {
	return service.get(BetslipUpsellConfig);
}

@LazyClientConfig({ key:'msBetStationEDSTriggers' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: betStationEDSTriggersConfigFactory,
})
export class BetStationEDSTriggersConfig extends LazyClientConfigBase {
	signpostingEnabled: boolean;
}

export function betStationEDSTriggersConfigFactory(service: LazyClientConfigService): BetStationEDSTriggersConfig {
	return service.get(BetStationEDSTriggersConfig);
}

@ClientConfig({ key:'msBetstationTopScreenModular' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betstationTopScreenModularConfigFactory,
})
export class BetstationTopScreenModularConfig {
	isEnabled: boolean;
}

export function betstationTopScreenModularConfigFactory(service: ClientConfigService): BetstationTopScreenModularConfig {
	return service.get(BetstationTopScreenModularConfig);
}

@ClientConfig({ key:'msBetstationWidget' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: betstationWidgetConfigFactory,
})
export class BetstationWidgetConfig {
	pageRefreshInterval: PageRefreshConfig[];
}

export function betstationWidgetConfigFactory(service: ClientConfigService): BetstationWidgetConfig {
	return service.get(BetstationWidgetConfig);
}

@ClientConfig({ key:'msBettingInsights' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: bettingInsightsConfigurationFactory,
})
export class BettingInsightsConfiguration {
	isEnabled: boolean;
}

export function bettingInsightsConfigurationFactory(service: ClientConfigService): BettingInsightsConfiguration {
	return service.get(BettingInsightsConfiguration);
}

@LazyClientConfig({ key:'msBettingInsightsSitecore' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: bettingInsightsSitecoreConfigFactory,
})
export class BettingInsightsSitecoreConfig extends LazyClientConfigBase {
	moneyBets: string;
	overLabel: string;
	spreadBets: string;
	totalBets: string;
	underLabel: string;
}

export function bettingInsightsSitecoreConfigFactory(service: LazyClientConfigService): BettingInsightsSitecoreConfig {
	return service.get(BettingInsightsSitecoreConfig);
}

@ClientConfig({ key:'msBettingOffer' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: bettingOfferConfigFactory,
})
export class BettingOfferConfig {
	golfSiblingMapping: {[key: string]: string;};
	includeLinkedFixtures: boolean;
	isMainToLiveTransitionEnabled: boolean;
	mainToLiveFixedPushDelay: number;
	mainToLiveMaxRandomPushDelay: number;
	pushBufferingDuration: number;
}

export function bettingOfferConfigFactory(service: ClientConfigService): BettingOfferConfig {
	return service.get(BettingOfferConfig);
}

@ClientConfig({ key:'msCalendar' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: calendarConfigFactory,
})
export class CalendarConfig {
	showCalendarTab: boolean;
	topMenuDateFormat: string;
}

export function calendarConfigFactory(service: ClientConfigService): CalendarConfig {
	return service.get(CalendarConfig);
}

@ClientConfig({ key:'msCalendarData' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: calendarDataFactory,
})
export class CalendarData {
	calendarItems: CalendarModel;
}

export function calendarDataFactory(service: ClientConfigService): CalendarData {
	return service.get(CalendarData);
}

@ClientConfig({ key:'msCasinoMiniGames' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: casinoMiniGamesConfigFactory,
})
export class CasinoMiniGamesConfig {
	appendSessionKey: boolean;
	areMiniGamesEnabled: boolean;
	blackListedCountries: string[];
	isKYCCheckEnabled: boolean;
	sportsSpinnerLoadingDelayTime: number;
	url?: string;
}

export function casinoMiniGamesConfigFactory(service: ClientConfigService): CasinoMiniGamesConfig {
	return service.get(CasinoMiniGamesConfig);
}

@ClientConfig({ key:'msCDNCaching' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: cDNCachingConfigFactory,
})
export class CDNCachingConfig {
	widgets: string[];
}

export function cDNCachingConfigFactory(service: ClientConfigService): CDNCachingConfig {
	return service.get(CDNCachingConfig);
}

@ClientConfig({ key:'msCds' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: cdsConfigFactory,
})
export class CdsConfig {
	maxFixtureBatchSize: number;
}

export function cdsConfigFactory(service: ClientConfigService): CdsConfig {
	return service.get(CdsConfig);
}

@ClientConfig({ key:'msClickInteraction' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: clickInteractionConfigurationFactory,
})
export class ClickInteractionConfiguration {
	isEnabled: boolean;
}

export function clickInteractionConfigurationFactory(service: ClientConfigService): ClickInteractionConfiguration {
	return service.get(ClickInteractionConfiguration);
}

@ClientConfig({ key:'msClientCaching' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: clientCachingConfigFactory,
})
export class ClientCachingConfig {
	coreNavigationCompetitionListTimeout: number;
	couponListTimeout: number;
	liveSportListTimeout: number;
	productMenuTimeout: number;
	quicklinkCountsTimeout: number;
	regionListTimeout: number;
	sportListTimeout: number;
	sportTimeout: number;
}

export function clientCachingConfigFactory(service: ClientConfigService): ClientCachingConfig {
	return service.get(ClientCachingConfig);
}

@ClientConfig({ key:'msCompetitionTree' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: competitionTreeConfigFactory,
})
export class CompetitionTreeConfig {
	showCountDsl: boolean;
}

export function competitionTreeConfigFactory(service: ClientConfigService): CompetitionTreeConfig {
	return service.get(CompetitionTreeConfig);
}

@ClientConfig({ key:'msComposable' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: composableConfigFactory,
})
export class ComposableConfig {
	numberOfEventsOnShowMoreClick: number;
	thresholdToExpandAccordion: number;
}

export function composableConfigFactory(service: ClientConfigService): ComposableConfig {
	return service.get(ComposableConfig);
}

@ClientConfig({ key:'msConnection' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: connectionConfigFactory,
})
export class ConnectionConfig {
	cdsMaxRetries: number;
	cdsPrefferedTransportType: number;
	cdsPushUrl: string;
	cdsSkipNegotiation: boolean;
	cdsUrlBase: string;
	culture: string;
	publicAccessId: string;
	pushAccessId: string;
	shopTier?: number;
}

export function connectionConfigFactory(service: ClientConfigService): ConnectionConfig {
	return service.get(ConnectionConfig);
}

@ClientConfig({ key:'msCrm' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: crmConfigFactory,
})
export class CrmConfig {
	promotions: CrmPromotionConfiguration;
	signposting: CrmPostBetSignpostingConfiguration;
	tokens: CrmTokenConfig;
}

export function crmConfigFactory(service: ClientConfigService): CrmConfig {
	return service.get(CrmConfig);
}

@ClientConfig({ key:'msCRMTokenImages' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: cRMTokenImagesFactory,
})
export class CRMTokenImages {
	crmTokenImages: CRMTokenImage[];
}

export function cRMTokenImagesFactory(service: ClientConfigService): CRMTokenImages {
	return service.get(CRMTokenImages);
}

@ClientConfig({ key:'msCurrency' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: currencyConfigFactory,
})
export class CurrencyConfig {
	clientCurrencyFormat?: string;
	currencySymbolDisplayList: string[];
	showStakeCurrency: boolean;
}

export function currencyConfigFactory(service: ClientConfigService): CurrencyConfig {
	return service.get(CurrencyConfig);
}

@ClientConfig({ key:'msCustomerDnaBettingOffer' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: customerDnaBettingOfferConfigFactory,
})
export class CustomerDnaBettingOfferConfig {
	isEnabled: boolean;
}

export function customerDnaBettingOfferConfigFactory(service: ClientConfigService): CustomerDnaBettingOfferConfig {
	return service.get(CustomerDnaBettingOfferConfig);
}

@ClientConfig({ key:'msCustomerDnaDefaultStakeTeaser' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: customerDnaDefaultStakeTeaserConfigFactory,
})
export class CustomerDnaDefaultStakeTeaserConfig {
	confirmationTimeout: number;
	isEnabled: boolean;
	restPeriod: number;
	teaserTimeout: number;
	teaserTreshold: number;
}

export function customerDnaDefaultStakeTeaserConfigFactory(service: ClientConfigService): CustomerDnaDefaultStakeTeaserConfig {
	return service.get(CustomerDnaDefaultStakeTeaserConfig);
}

@ClientConfig({ key:'msEarlyPayout' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: earlyPayoutConfigFactory,
})
export class EarlyPayoutConfig {
	autoCashOutMarginValue: number;
	isAutoCashOutEnabled: boolean;
	isAutoCashOutHelpEnabled: boolean;
	isAutoCashOutNotificationEnabled: boolean;
	isEarlyPayoutEnabled: boolean;
	isEarlyPayoutPushEnabled: boolean;
	isEarlyPayoutTACDialogueEnabled: boolean;
	showCashOutInfo: boolean;
}

export function earlyPayoutConfigFactory(service: ClientConfigService): EarlyPayoutConfig {
	return service.get(EarlyPayoutConfig);
}

@ClientConfig({ key:'msEpcot' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: epcotConfigFactory,
})
export class EpcotConfig {
	isEnabled: boolean;
	modules: string[];
	sitemapDrawerTooltipVisibilityCount: number;
}

export function epcotConfigFactory(service: ClientConfigService): EpcotConfig {
	return service.get(EpcotConfig);
}

@ClientConfig({ key:'msEventDetails' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: eventDetailsConfigFactory,
})
export class EventDetailsConfig {
	collapsibleChevron: boolean;
	defaultExpandedMarketsCount: number;
	enableCorrectScoreOptionGroupToggle: boolean;
	enableEventPageModular: boolean;
	enableHideBreadcrumbOnScroll: boolean;
	enableSitemapNavigation: boolean;
	enableV2LiveStats: boolean;
	headerNavigationScoreboard: {[key: string]: number[];};
	isHeaderSubNavigationEnabled: boolean;
	isNewScoreboardEnabled: boolean;
	isSuspendedSelectionVisibilityEnabled: boolean;
	marketTemplatesWithThresholds: {[key: string]: string[];};
	mediaSwitcherTabs: {[key: string]: {[key: string]: string[];};};
	messageIcons: {[key: string]: {[key: string]: IconInfo;};};
	siteMapTooltipViewsThreshold: number;
	tabBarTooltipViewsThreshold: number;
	teamIndicatorFallbackColors: string[];
}

export function eventDetailsConfigFactory(service: ClientConfigService): EventDetailsConfig {
	return service.get(EventDetailsConfig);
}

@ClientConfig({ key:'msEventSwitcher' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: eventSwitcherConfigFactory,
})
export class EventSwitcherConfig {
	isEnabled: boolean;
	toggleIconAllowedSports: number[];
}

export function eventSwitcherConfigFactory(service: ClientConfigService): EventSwitcherConfig {
	return service.get(EventSwitcherConfig);
}

@ClientConfig({ key:'msFavourites' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: favouritesConfigFactory,
})
export class FavouritesConfig {
	collapseSize: number;
	initialSize: number;
	isFavouriteSwipeEnabled: boolean;
	isLiveFavouritesEnabled: boolean;
	isLiveWidgetFavouritesEnabled: boolean;
	maxFavourites: number;
	maxWidgetFavourites: number;
	pageSize: number;
	toastMessageTimeOut: number;
}

export function favouritesConfigFactory(service: ClientConfigService): FavouritesConfig {
	return service.get(FavouritesConfig);
}

@ClientConfig({ key:'msFormCoupon' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: formCouponConfigFactory,
})
export class FormCouponConfig {
	quickSelectorButtons: number[];
}

export function formCouponConfigFactory(service: ClientConfigService): FormCouponConfig {
	return service.get(FormCouponConfig);
}

@ClientConfig({ key:'msGeolocation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: geolocationConfigFactory,
})
export class GeolocationConfig {
	appUrl?: string;
	bootstrapAssetPaths: string[];
	coordinatorSettings: CoordinatorSettings;
	gdsFetchLocationUrl?: string;
	gdsUrl?: string;
	geoGuardAppUrl?: string;
	geoPollingUrlPath?: string;
	initialRetryInterval: number;
	installerSettings: InstallerSettings;
	isBetRestrictionEnabled: boolean;
	isGeolocationEnabled: boolean;
	isRetryButtonEnabled: boolean;
	logGenericFailureResults: boolean;
	retriableErrorCodes: number[];
	retryInterval: number;
	shouldCheckNativeGeoLocation: boolean;
}

export function geolocationConfigFactory(service: ClientConfigService): GeolocationConfig {
	return service.get(GeolocationConfig);
}

@ClientConfig({ key:'msGrid' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: gridConfigFactory,
})
export class GridConfig {
	areEmptyEventStatisticsVisible: boolean;
	collapseSize?: number;
	fallbackMarketOddsThreshold: number;
	fallbackMarketsEnabled: boolean;
	gameNotesMaxCount: number;
	goalAnimationTimeInterval: number;
	hideGridTitle: boolean;
	initialSize: number;
	is2way3wayEnabled: boolean;
	isAllMarketsLinkEnabled: boolean;
	isGameNotesLayoutEnabled: boolean;
	isGoalAnimationEnabled: number[];
	isMarketPillsEnabled: boolean;
	isRegionalisedGridEnabled: boolean;
	isSixPackLayoutEnabled: boolean;
	overriddenCompetitionLogo: SportCompetitionFormat[];
	pageSize: number;
	showMarketPillsOnboardingMessage: boolean;
	showMoreRowsThreshold: number;
	showParticipantLogoOrImage: boolean;
	showRedCardsInSoccer: boolean;
	verticalGridGroupIds: {[key: string]: VerticalGridGroupOption;};
}

export function gridConfigFactory(service: ClientConfigService): GridConfig {
	return service.get(GridConfig);
}

@ClientConfig({ key:'msGTM' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: gTMConfigFactory,
})
export class GTMConfig {
	delay: number;
	enabled: boolean;
}

export function gTMConfigFactory(service: ClientConfigService): GTMConfig {
	return service.get(GTMConfig);
}

@ClientConfig({ key:'msImages' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: imagesConfigFactory,
})
export class ImagesConfig {
	competitionLogoUrl: string;
	competitionLogos: SportCompetitionFormat[];
	headshotEnabledOptionGroups: {[key: number]: string[];};
	imageQualityParams: ImageCompressionFormat;
	isCompetitionImageProfile: boolean;
	isLogoBackgroundEnabled: boolean;
	isParticipantImageEnabled: boolean;
	participantImageEnabledMarketTypes: {[key: number]: string[];};
	participantImageUrl: string;
	participantLogoUrl: string;
	silkImageUrl: string;
}

export function imagesConfigFactory(service: ClientConfigService): ImagesConfig {
	return service.get(ImagesConfig);
}

@ClientConfig({ key:'msImgConfigurationData' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: imgConfigurationDataFactory,
})
export class ImgConfigurationData {
	blacklistedCompetitions: number[];
	hoursToShowImgAnimation: number;
	isEnabled: boolean;
	isVideoPlaybackEnabled: boolean;
	languageMapping: {[key: string]: string;};
	pitchConfiguration: {[key: string]: string;};
}

export function imgConfigurationDataFactory(service: ClientConfigService): ImgConfigurationData {
	return service.get(ImgConfigurationData);
}

@ClientConfig({ key:'msInternalFeatures' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: internalFeaturesConfigFactory,
})
export class InternalFeaturesConfig {
	isAutomationDebugEnabled: boolean;
	isBalanceLoggingEnabled: boolean;
	isBetplacementLoggingEnabled: boolean;
	isEPSDetailedLoggingEnabled: boolean;
}

export function internalFeaturesConfigFactory(service: ClientConfigService): InternalFeaturesConfig {
	return service.get(InternalFeaturesConfig);
}

@ClientConfig({ key:'msIntroPages' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: introPagesConfigFactory,
})
export class IntroPagesConfig {
	cookieName: string;
	id: string;
	slides: HeadlineModel[];
}

export function introPagesConfigFactory(service: ClientConfigService): IntroPagesConfig {
	return service.get(IntroPagesConfig);
}

@LazyClientConfig({ key:'msIsQuickDepositAllowedResult' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: isQuickDepositAllowedResultFactory,
})
export class IsQuickDepositAllowedResult extends LazyClientConfigBase {
	isQuickDepositAllowed: boolean;
}

export function isQuickDepositAllowedResultFactory(service: LazyClientConfigService): IsQuickDepositAllowedResult {
	return service.get(IsQuickDepositAllowedResult);
}

@ClientConfig({ key:'msLayoutNavigation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: layoutNavigationConfigFactory,
})
export class LayoutNavigationConfig {
	bottomNavItemsMapping: {[key: string]: string;};
	hideMidSectionBreadCrumbItems: boolean;
	isDesktopTopNavigationCompact: boolean;
	isDynamicCategoryNavigationEnabled: boolean;
	topNavigationVersion: string;
}

export function layoutNavigationConfigFactory(service: ClientConfigService): LayoutNavigationConfig {
	return service.get(LayoutNavigationConfig);
}

@ClientConfig({ key:'msLineSwitcher' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: lineSwitcherConfigFactory,
})
export class LineSwitcherConfig {
	disabledLeagues: number[];
	disabledSports: number[];
	isHelpBoxEnabled: boolean;
}

export function lineSwitcherConfigFactory(service: ClientConfigService): LineSwitcherConfig {
	return service.get(LineSwitcherConfig);
}

@ClientConfig({ key:'msLinkExternalAccount' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: linkExternalAccountConfigFactory,
})
export class LinkExternalAccountConfig {
	isEnabled: boolean;
}

export function linkExternalAccountConfigFactory(service: ClientConfigService): LinkExternalAccountConfig {
	return service.get(LinkExternalAccountConfig);
}

@ClientConfig({ key:'msLiveNavigation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: liveNavigationConfigFactory,
})
export class LiveNavigationConfig {
	isEachWayMarketsTabEnabled: boolean;
	isLiveEventsNavigationEnabled: boolean;
	loadingIndicatorDelay: number;
	pollingInterval: number;
	treshold: number;
}

export function liveNavigationConfigFactory(service: ClientConfigService): LiveNavigationConfig {
	return service.get(LiveNavigationConfig);
}

@ClientConfig({ key:'msLiveSection' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: liveSectionConfigFactory,
})
export class LiveSectionConfig {
	activeParticipantIconForSports: number[];
	hideFiltersForSportIds: number[];
	isPagingEnabled: boolean;
	liveEventsInfoForLeagueIds: number[];
	maxLiveEventsPerSport: number;
	maxUpcomingEventsPerSport: number;
	minEventsPagingSize: number;
	pageSize: number;
	showLiveBadgeCount: string[];
}

export function liveSectionConfigFactory(service: ClientConfigService): LiveSectionConfig {
	return service.get(LiveSectionConfig);
}

@ClientConfig({ key:'msLobby' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: lobbyConfigFactory,
})
export class LobbyConfig {
	homePageGridsRefreshTimeout: number;
	homePageRefreshTimeout: number;
	liveSectionNumberOfEventsPerSport: number;
	liveSectionNumberOfSports: number;
	mainSectionNumberOfEventsPerSport: number;
	mainSectionNumberOfSports: number;
	orderedRenderingTimeout: number;
	topPageRefreshTimeout: number;
}

export function lobbyConfigFactory(service: ClientConfigService): LobbyConfig {
	return service.get(LobbyConfig);
}

@ClientConfig({ key:'msMarketGrouping' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: marketGroupingConfigFactory,
})
export class MarketGroupingConfig {
	correctScoreOptionsSortOrder?: string;
	displayTypes: string[];
	eventCarouselVisibilityConfig: EventCarouselVisibilityConfig[];
	isOverUnderAbbreviationEnabled: boolean;
	marketGroupsWithNewBadge: MarketGroupsWithNewBadge[];
	milestoneMarketsDefaultTab: {[key: string]: number;};
	optionCollapseSize: OptionPanelThreshold[];
	optionGroupRowsThreshold: OptionGroupRowsThreshold;
	optionTypesToFilterTV2MarketOptions: OptionTypesToFilterTV2MarketOptions[];
	orderByLineValue: boolean;
	outrightsGroupMaxNumberOfLines: number;
	overUnderGroupMaxNumberOfLines: number;
	overrideParticipantNameOptionMarkets: OverrideParticipantNameOptionMarkets[];
	playerCombinedGroupMaxLines: number;
	playerMilestoneGroupMaxLines: number;
	playerPropsGroupMaxNumberOfLines: number;
	raceToGroupMaxNumberOfLines: number;
	regionalisedGroupingEnabled: boolean;
	scorecastPossibleWinningsVisible: boolean;
	scorecastRegexValues: ScorecastRegexValues;
	scorecastSelectionCacheDurationMs: number;
	shouldShowSheetViewForAllMarkets: boolean;
	showMoreBetBuilderCorrectScoreThreshold: number;
	showMoreCorrectScoreThreshold: number;
	showMoreRegularThreshold: number;
	spreadGroupMaxNumberOfLines: number;
	teamGroupingMaxNumberOfLines: number;
	teamGroupingTabVisibilityThreshold: number;
}

export function marketGroupingConfigFactory(service: ClientConfigService): MarketGroupingConfig {
	return service.get(MarketGroupingConfig);
}

@ClientConfig({ key:'msMarketHelp' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: marketHelpConfigFactory,
})
export class MarketHelpConfig {
	enabled: boolean;
	marketHelpCacheTimeout: number;
	sitecoreFallbackItem: string;
}

export function marketHelpConfigFactory(service: ClientConfigService): MarketHelpConfig {
	return service.get(MarketHelpConfig);
}

@ClientConfig({ key:'msMarquee' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: marqueeConfigFactory,
})
export class MarqueeConfig {
	automatedMarqueesOrder: string[];
	automatedRegularMarqueesOrder: {[key: string]: string[];};
	disabledSportIds: number[];
	dynamicMarkets: string[];
	isAutomatedMarqueesEnabled: boolean;
	isFallbackMarqueeEnabled: boolean;
	isHighlightsMarqueeEnabled: boolean;
	mapMarketToGameTemplateIds: MapMarketToGameTemplateIds[];
	marketListWithOptionDropdown: string[];
	marqueeGroupsOfMarkets: MarqueeGroupsOfMarkets[];
	marqueeMarketOverrides: {[key: string]: string;};
	maxCorrectScoreGoals: number;
	maxMarketsToShowInOptionDropdown: number;
	maxRequestedEvents: number;
	maxTiles: number;
	oddThresholdValue: number;
	scrollingSpeed: {[key: string]: string;};
	sixPackGridGroupMapping: SixPackGridGroupMapping[];
	specialLeagueIds: number[];
}

export function marqueeConfigFactory(service: ClientConfigService): MarqueeConfig {
	return service.get(MarqueeConfig);
}

@LazyClientConfig({ key:'msMarqueeTiles' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: marqueeTilesConfigFactory,
})
export class MarqueeTilesConfig extends LazyClientConfigBase {
	tiles: MarqueeTile[];
}

export function marqueeTilesConfigFactory(service: LazyClientConfigService): MarqueeTilesConfig {
	return service.get(MarqueeTilesConfig);
}

@ClientConfig({ key:'msMatchList' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: matchListConfigFactory,
})
export class MatchListConfig {
	maxEventThreshold: number;
	showLoader: boolean;
}

export function matchListConfigFactory(service: ClientConfigService): MatchListConfig {
	return service.get(MatchListConfig);
}

@ClientConfig({ key:'msMedia' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: mediaConfigFactory,
})
export class MediaConfig {
	expandedMediaEnabledLocations: string[];
	isExpandedMediaEnabled: boolean;
	isMatchSimulationEnabled: boolean;
	isVideoStreamFullscreenEnabled: boolean;
	matchSimulation: MatchSimulationConfiguration;
	restrictedSportIds: number[];
	showStatsCenterButton: boolean;
	showVideoStreaming: boolean;
	sportsWithMatchSimulation: number[];
	subdivisionRestrictedCountries: string[];
	twitchPlayerLibraryUrl: string;
	videoStreamGeoCoordinatesCountries: string[];
	videoUrlRefreshInterval: {[key: string]: number;};
}

export function mediaConfigFactory(service: ClientConfigService): MediaConfig {
	return service.get(MediaConfig);
}

@LazyClientConfig({ key:'msMobileConfirmationNotificationClient' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: mobileConfirmationNotificationClientConfigFactory,
})
export class MobileConfirmationNotificationClientConfig extends LazyClientConfigBase {
	confirmationIntervalMilliseconds: number;
	isEnabled: boolean;
}

export function mobileConfirmationNotificationClientConfigFactory(service: LazyClientConfigService): MobileConfirmationNotificationClientConfig {
	return service.get(MobileConfirmationNotificationClientConfig);
}

@ClientConfig({ key:'msMocks' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: mocksConfigFactory,
})
export class MocksConfig {
	combiPlusLeagues: number[];
	isMockCombiPlusLeaguesEnabled: boolean;
	isMockLiveStreamGeoCountryEnabled: boolean;
	liveStreamGeoCountry?: string;
}

export function mocksConfigFactory(service: ClientConfigService): MocksConfig {
	return service.get(MocksConfig);
}

@ClientConfig({ key:'msMsPage' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: msPageFactory,
})
export class MsPage {
	title?: string;
}

export function msPageFactory(service: ClientConfigService): MsPage {
	return service.get(MsPage);
}

@LazyClientConfig({ key:'msMultiBuilder' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: multiBuilderConfigFactory,
})
export class MultiBuilderConfig extends LazyClientConfigBase {
	cacheTimeOut: number;
	competitionOrder: {[key: number]: number;};
	isEnabled: boolean;
	marketGridGroups: MarketGridGroup[];
	maxCompetitionsCount: number;
	maxFixturesCount: number;
	showStats: boolean;
}

export function multiBuilderConfigFactory(service: LazyClientConfigService): MultiBuilderConfig {
	return service.get(MultiBuilderConfig);
}

@LazyClientConfig({ key:'msMultiSportsHub' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: multiSportsHubConfigFactory,
})
export class MultiSportsHubConfig extends LazyClientConfigBase {
	isEnabled: boolean;
}

export function multiSportsHubConfigFactory(service: LazyClientConfigService): MultiSportsHubConfig {
	return service.get(MultiSportsHubConfig);
}

@LazyClientConfig({ key:'msMyBetsBetstation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: myBetsBetstationConfigFactory,
})
export class MyBetsBetstationConfig extends LazyClientConfigBase {
	delayForPayoutStatusCheckAfterCashout: number;
	intervalForPayoutVerifyCalls: number;
	myBetsPageSize: number;
	openBetReloadDataInterval: number;
	payoutVerifyRetryAttemps: number;
	timeOutToHideBetAfterCashout: number;
}

export function myBetsBetstationConfigFactory(service: LazyClientConfigService): MyBetsBetstationConfig {
	return service.get(MyBetsBetstationConfig);
}

@ClientConfig({ key:'msMyBets' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: myBetsConfigFactory,
})
export class MyBetsConfig {
	archivedMyBetsUrl?: string;
	cacheTimeout: number;
	defaultDaysBeforeMigrationDate: number;
	earlyPayoutButtonResetTime: string;
	editMyBetTimeout: number;
	enableBalanceRefresh: boolean;
	enablePinning: boolean;
	enableSGPRepricedOdds: boolean;
	isArchivedBetsEnabled: boolean;
	isEditMyBetEnabled: boolean;
	isIndicatorConnectorEnabled: boolean;
	isOpenSummaryLoadingRestricted: boolean;
	isShowOpenBetsCount: boolean;
	isTransactionBetsEnabled: boolean;
	liveBetsRefreshInterval: string;
	maxPins: number;
	myBetsRefreshInMilliseconds: number;
	pageSize: number;
	reloadMyBetsTimeInterval: number;
	showAssociatedAccount: boolean;
	showNetWinnings: boolean;
	showPrintBetButtons: boolean;
	showSportAndCompetitionName: boolean;
	stadiumMyBetsTransactionsUrl?: string;
	useOpenBetsPostRequest: boolean;
}

export function myBetsConfigFactory(service: ClientConfigService): MyBetsConfig {
	return service.get(MyBetsConfig);
}

@LazyClientConfig({ key:'msMyBetsVisualizationTracking' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: myBetsVisualizationTrackingConfigFactory,
})
export class MyBetsVisualizationTrackingConfig extends LazyClientConfigBase {
	allowedLeagues: {[key: number]: number[];};
	isEnabled: boolean;
	marketTemplateMapping: {[key: number]: {[key: string]: number[];};};
	targetStatForMarket: {[key: number]: number[];};
}

export function myBetsVisualizationTrackingConfigFactory(service: LazyClientConfigService): MyBetsVisualizationTrackingConfig {
	return service.get(MyBetsVisualizationTrackingConfig);
}

@ClientConfig({ key:'msNativeAlerts' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: nativeAlertsConfigFactory,
})
export class NativeAlertsConfig {
	appNotificationsEnabledCheck: boolean;
	enabled: boolean;
	fetchingInterval: number;
	forceLegacyPosApiEndpoint: boolean;
	sports: SportConfiguration[];
}

export function nativeAlertsConfigFactory(service: ClientConfigService): NativeAlertsConfig {
	return service.get(NativeAlertsConfig);
}

@ClientConfig({ key:'msNewCustomerBettingOffer' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: newCustomerBettingOfferConfigFactory,
})
export class NewCustomerBettingOfferConfig {
	competitionId?: number;
}

export function newCustomerBettingOfferConfigFactory(service: ClientConfigService): NewCustomerBettingOfferConfig {
	return service.get(NewCustomerBettingOfferConfig);
}

@LazyClientConfig({ key:'msNextToGo' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: nextToGoConfigFactory,
})
export class NextToGoConfig extends LazyClientConfigBase {
	intervalForRedPreMatchTimer: number;
	marketGridGroupIds: NextToGoGroupsOfMarkets[];
	maxEventsPerSport: number;
	minEventsPerSport: number;
	module: NextToGoModule;
	showOdds: boolean;
	showParticipantLogoOrImage: boolean;
	sportsToDisplayCompetitionLogo: number[];
	timeFrame: number;
}

export function nextToGoConfigFactory(service: LazyClientConfigService): NextToGoConfig {
	return service.get(NextToGoConfig);
}

@ClientConfig({ key:'msNgrxReports' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: ngrxReportsConfigurationFactory,
})
export class NgrxReportsConfiguration {
	actionsUpdateThreshold: number;
	isReportsEnabled: boolean;
	maxActions: number;
	restrictedActions: string[];
}

export function ngrxReportsConfigurationFactory(service: ClientConfigService): NgrxReportsConfiguration {
	return service.get(NgrxReportsConfiguration);
}

@ClientConfig({ key:'msNotifications' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: notificationsConfigFactory,
})
export class NotificationsConfig {
	appBetNotificationsDisabledForSports: DisabledNotificationSport[];
	emailDisabledForSports: DisabledNotificationSport[];
	isAppBetNotificationsEnabled: boolean;
	isEmailBetNotificationsEnabled: boolean;
}

export function notificationsConfigFactory(service: ClientConfigService): NotificationsConfig {
	return service.get(NotificationsConfig);
}

@ClientConfig({ key:'msOdds' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: oddsConfigFactory,
})
export class OddsConfig {
	allowedOddsFormats: OddsDisplayFormat[];
	display: OddsDisplayConfiguration;
	hideDontAcceptOptionSetting: boolean;
	oddsAcceptanceTeaserThreshold: number;
	oddsFormatCookieTimeSpan: number;
	preferencesReminder: BetslipOddsPreferencesReminderConfiguration;
	showOddsAcceptanceSettings: boolean;
}

export function oddsConfigFactory(service: ClientConfigService): OddsConfig {
	return service.get(OddsConfig);
}

@ClientConfig({ key:'msOddsFormatSettings' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: oddsFormatSettingsFactory,
})
export class OddsFormatSettings {
	generalOddsFormat: OddsDisplayFormat;
}

export function oddsFormatSettingsFactory(service: ClientConfigService): OddsFormatSettings {
	return service.get(OddsFormatSettings);
}

@ClientConfig({ key:'msOutrightsGrid' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: outrightsGridConfigFactory,
})
export class OutrightsGridConfig {
	enableParticipantImages: number[];
	isEnabled: boolean;
	isMarketPillsEnabled: boolean;
	maxCompetitions: number;
	maxRowsThreshold: number;
	overrideParticipantImageNameFormat: ParticipantImageFormat[];
	showParticipantLogo: boolean;
}

export function outrightsGridConfigFactory(service: ClientConfigService): OutrightsGridConfig {
	return service.get(OutrightsGridConfig);
}

@ClientConfig({ key:'msOverAsk' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: overAskConfigFactory,
})
export class OverAskConfig {
	pauseThreshold: number;
	pendingShouldShowPercentage: boolean;
	reviewProgressbarTotalSteps: number;
	reviewWaitingTime: number;
	showStakeChanges: boolean;
}

export function overAskConfigFactory(service: ClientConfigService): OverAskConfig {
	return service.get(OverAskConfig);
}

@ClientConfig({ key:'msOverrideStatistics' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: overrideStatisticsConfigFactory,
})
export class OverrideStatisticsConfig {
	teamRankings: {[key: number]: {[key: number]: {[key: number]: number;};};};
}

export function overrideStatisticsConfigFactory(service: ClientConfigService): OverrideStatisticsConfig {
	return service.get(OverrideStatisticsConfig);
}

@ClientConfig({ key:'msParticipants' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: participantsConfigFactory,
})
export class ParticipantsConfig {
	enabledShortNames: {[key: number]: {[key: number]: string[];};};
}

export function participantsConfigFactory(service: ClientConfigService): ParticipantsConfig {
	return service.get(ParticipantsConfig);
}

@ClientConfig({ key:'msPerformanceProfiling' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: performanceProfilingConfigFactory,
})
export class PerformanceProfilingConfig {
	isPerformanceProfilingEnabled: boolean;
}

export function performanceProfilingConfigFactory(service: ClientConfigService): PerformanceProfilingConfig {
	return service.get(PerformanceProfilingConfig);
}

@ClientConfig({ key:'msPersonalizedMarquee' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: personalizedMarqueeConfigFactory,
})
export class PersonalizedMarqueeConfig {
	checkABUserCookie: boolean;
	enablePersonalizedMarquee: boolean;
	minEventsToShow: number;
	showOnlyPersonalizedMarquees: boolean;
}

export function personalizedMarqueeConfigFactory(service: ClientConfigService): PersonalizedMarqueeConfig {
	return service.get(PersonalizedMarqueeConfig);
}

@LazyClientConfig({ key:'msPopularBets' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: popularBetsConfigFactory,
})
export class PopularBetsConfig extends LazyClientConfigBase {
	bufferTimeToIgnoreLiveEvents: number;
	enableLogos: boolean;
	isMultiBetsModuleEnabled: boolean;
	minCountForShowingCounter: number;
	minOptionsToShowForMultiSelectionPod: number;
	minOptionsToShowForSingleSelectionPod: number;
	timeFrame: number;
}

export function popularBetsConfigFactory(service: LazyClientConfigService): PopularBetsConfig {
	return service.get(PopularBetsConfig);
}

@ClientConfig({ key:'msPopularSearchData' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: popularSearchDataFactory,
})
export class PopularSearchData {
	popularList: PopularSearchModel[];
}

export function popularSearchDataFactory(service: ClientConfigService): PopularSearchData {
	return service.get(PopularSearchData);
}

@ClientConfig({ key:'msPopups' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: popupsConfigFactory,
})
export class PopupsConfig {
	slidesFromBottom: {[key: string]: boolean;};
	slidesFromRight: {[key: string]: boolean;};
}

export function popupsConfigFactory(service: ClientConfigService): PopupsConfig {
	return service.get(PopupsConfig);
}

@ClientConfig({ key:'msPrematchNavigation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: prematchNavigationConfigFactory,
})
export class PrematchNavigationConfig {
	isPrematchEventsNavigationEnabled: boolean;
	pollingInterval: number;
	treshold: number;
}

export function prematchNavigationConfigFactory(service: ClientConfigService): PrematchNavigationConfig {
	return service.get(PrematchNavigationConfig);
}

@ClientConfig({ key:'msPrettyUrls' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: prettyUrlsConfigFactory,
})
export class PrettyUrlsConfig {
	maxTokenLength: number;
	tokenSanitizer: string;
	translations: {[key: string]: string;};
}

export function prettyUrlsConfigFactory(service: ClientConfigService): PrettyUrlsConfig {
	return service.get(PrettyUrlsConfig);
}

@LazyClientConfig({ key:'msPreviousPageLocation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: previousPageLocationConfigurationFactory,
})
export class PreviousPageLocationConfiguration extends LazyClientConfigBase {
	caheTimeOut: number;
	enabled: boolean;
}

export function previousPageLocationConfigurationFactory(service: LazyClientConfigService): PreviousPageLocationConfiguration {
	return service.get(PreviousPageLocationConfiguration);
}

@ClientConfig({ key:'msPriceBoost' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: priceBoostConfigFactory,
})
export class PriceBoostConfig {
	fixturesPageSize: number;
	isEnabled: boolean;
	isEnabledAPB: boolean;
	maxGroupOptions: number;
	showAsTab: boolean;
	showCount: boolean;
	showCountForLive: boolean;
}

export function priceBoostConfigFactory(service: ClientConfigService): PriceBoostConfig {
	return service.get(PriceBoostConfig);
}

@ClientConfig({ key:'msQuickBet' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: quickBetConfigFactory,
})
export class QuickBetConfig {
	isAddToBetslipCtaTextEnabled: boolean;
	isClearCtaTextEnabled: boolean;
	isContextualInfoEnabled: boolean;
	isEnabled: boolean;
	isEnhancedStakeEnabled: boolean;
	isHeaderEnabled: boolean;
	isQuickBetParlayEnabled: boolean;
	isTotalReturnsEnabled: boolean;
	isUserBalanceEnabled: boolean;
	showOnAllPages: boolean;
	showOnEventDetails: boolean;
	showOnLivePage: boolean;
}

export function quickBetConfigFactory(service: ClientConfigService): QuickBetConfig {
	return service.get(QuickBetConfig);
}

@ClientConfig({ key:'msQuickDeposit' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: quickDepositConfigFactory,
})
export class QuickDepositConfig {
	isQuickDepositEnabled: boolean;
	loadQuickDepositConfigOnLogin: boolean;
	path: string;
	url?: string;
}

export function quickDepositConfigFactory(service: ClientConfigService): QuickDepositConfig {
	return service.get(QuickDepositConfig);
}

@LazyClientConfig({ key:'msQuickLinks' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: quickLinksFactory,
})
export class QuickLinks extends LazyClientConfigBase {
	layout: LinkListLayout;
	links: QuickLinkViewModel[];
}

export function quickLinksFactory(service: LazyClientConfigService): QuickLinks {
	return service.get(QuickLinks);
}

@ClientConfig({ key:'msRacing' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: racingConfigFactory,
})
export class RacingConfig {
	bogTodayThresholdTime: string;
	bogVersion: BogVersion;
	canBogTokenBeUsedWithFreebetToken: boolean;
	countdownTimer: number;
	easyBetViewOptions: HorseRacingEasyBetViewOption[];
	enabledTabs: {[key: number]: string[];};
	filterRegionIds: string;
	greyhoundsEasyBetViewOptions: GreyhoundsRacingEasyBetViewOption[];
	isBOGEnabled: boolean;
	isGreyhoundsEnabled: boolean;
	maxPromos: number;
	maxPromosForMobile: number;
	minPromos: number;
	minPromosForMobile: number;
	nextFixturesCount: number;
	refreshInterval: number;
	showAllRaces: boolean;
}

export function racingConfigFactory(service: ClientConfigService): RacingConfig {
	return service.get(RacingConfig);
}

@LazyClientConfig({ key:'msRacingPromos' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: racingPromosFactory,
})
export class RacingPromos extends LazyClientConfigBase {
	promos: MarketingViewModel[];
}

export function racingPromosFactory(service: LazyClientConfigService): RacingPromos {
	return service.get(RacingPromos);
}

@LazyClientConfig({ key:'msRandomMultiGenerator' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: randomMultiGeneratorConfigFactory,
})
export class RandomMultiGeneratorConfig extends LazyClientConfigBase {
	autoSpinningInterval: number;
	defaultMaxOdds: number;
	defaultMinOdds: number;
	defaultMinWinnings: number;
	defaultSelections: number;
	defaultStake: number;
	historyLength: number;
	isEnabled: boolean;
	marketMapping: {[key: number]: MarketMappingConfiguration[];};
	maxSelections: number;
	minOddsFallback: number;
	minSelections: number;
	oddsRange: number[];
	possibleWinningsRange: number[];
	sports: RandomMultiGeneratorSportsSelection;
	sportsConfig: RandomMultiGeneratorSportsConfig[];
}

export function randomMultiGeneratorConfigFactory(service: LazyClientConfigService): RandomMultiGeneratorConfig {
	return service.get(RandomMultiGeneratorConfig);
}

@ClientConfig({ key:'msRealityCheck' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: realityCheckConfigFactory,
})
export class RealityCheckConfig {
	cookieName: string;
	jurisdictions: string[];
	realityCheckEvent: string;
	updateInterval: number;
}

export function realityCheckConfigFactory(service: ClientConfigService): RealityCheckConfig {
	return service.get(RealityCheckConfig);
}

@ClientConfig({ key:'msReBet' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: reBetConfigFactory,
})
export class ReBetConfig {
	ctaDescriptionEnabledTabs: string[];
	ctaSubtitleEnabledTabs: string[];
	enabledTabs: string[];
	helpBoxEnabledTabs: string[];
}

export function reBetConfigFactory(service: ClientConfigService): ReBetConfig {
	return service.get(ReBetConfig);
}

@ClientConfig({ key:'msRegionalQuickLinks' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: regionalQuickLinksConfigFactory,
})
export class RegionalQuickLinksConfig {
	betConfirmationLinks: QuickLinkViewModel[];
	emptyBetslipLinks: QuickLinkViewModel[];
}

export function regionalQuickLinksConfigFactory(service: ClientConfigService): RegionalQuickLinksConfig {
	return service.get(RegionalQuickLinksConfig);
}

@ClientConfig({ key:'msRegions' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: regionsConfigFactory,
})
export class RegionsConfig {
	listOfLeaguesToHideRegionName: number[];
	listOfRegionsToHideRegionName: number[];
	regionNames: string[];
}

export function regionsConfigFactory(service: ClientConfigService): RegionsConfig {
	return service.get(RegionsConfig);
}

@ClientConfig({ key:'msReturnsMessage' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: returnsMessageConfigFactory,
})
export class ReturnsMessageConfig {
	defaultStakes: {[key: string]: number;};
	persistenceTime: number;
}

export function returnsMessageConfigFactory(service: ClientConfigService): ReturnsMessageConfig {
	return service.get(ReturnsMessageConfig);
}

@ClientConfig({ key:'msScoreboardBackground' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: scoreboardBackgroundConfigFactory,
})
export class ScoreboardBackgroundConfig {
	backgrounds: ScoreboardBackground[];
}

export function scoreboardBackgroundConfigFactory(service: ClientConfigService): ScoreboardBackgroundConfig {
	return service.get(ScoreboardBackgroundConfig);
}

@ClientConfig({ key:'msScrollingSize' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: scrollingSizeConfigFactory,
})
export class ScrollingSizeConfig {
	hideHeaderOnScroll: string[];
	marqueeScrollSize: number;
	regionScrollSize: number;
	scrollThreshold: number;
	scrollingSpeed: {[key: string]: number;};
	sportScrollSize: number;
	topNavScrollSize: number;
}

export function scrollingSizeConfigFactory(service: ClientConfigService): ScrollingSizeConfig {
	return service.get(ScrollingSizeConfig);
}

@ClientConfig({ key:'msSeo' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: seoConfigFactory,
})
export class SeoConfig {
	clientLoggingDebounce: number;
	isClientCatchallLoggingEnabled: boolean;
	isPartialCollapsibleContentEnabled: boolean;
	removeHrefToEventForRobot: boolean;
}

export function seoConfigFactory(service: ClientConfigService): SeoConfig {
	return service.get(SeoConfig);
}

@ClientConfig({ key:'msShareMyBet' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: shareMyBetConfigFactory,
})
export class ShareMyBetConfig {
	complianceCookieExpiryDays: number;
	globalErrorVisibilityTime: number;
	isEnabled: boolean;
	isHelpBoxEnabled: boolean;
	isNewBadgeEnabled: boolean;
	isOnboardingEnabled: boolean;
	showBetSharingConfirmationOnlyOnFirstBetShare: boolean;
}

export function shareMyBetConfigFactory(service: ClientConfigService): ShareMyBetConfig {
	return service.get(ShareMyBetConfig);
}

@ClientConfig({ key:'msShowcase' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: showcaseConfigFactory,
})
export class ShowcaseConfig {
	alternateMarkets: {[key: string]: string;};
	backgrounds: BackgroundImage[];
	enabledSportIds: number[];
	isShowcaseModuleEnabled: boolean;
	items: ShowcaseViewModel[];
	marketGridGroupIds: ShowcaseGroupsOfMarkets[];
	nextGoalMarketGroupId: string;
	sixPackSportTypeIds: number[];
}

export function showcaseConfigFactory(service: ClientConfigService): ShowcaseConfig {
	return service.get(ShowcaseConfig);
}

@LazyClientConfig({ key:'msSitecore' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sitecoreFactory,
})
export class Sitecore extends LazyClientConfigBase {
	accaBoost: SitecoreAccaBoost;
	betBuilder: BetBuilder;
	betBuilderEntainUI: BetBuilderEntainUI;
	betBuilderHelpPage: BetBuilderHelpPage;
	betFinder: BetFinder;
	betStation?: BetStation;
	betslip: Betslip;
	betslipSettings: BetslipSettings;
	betslipUpsell: BetslipUpsell;
	bettingPreferences: BettingPreferences;
	cashoutSettings: CashoutSettings;
	combiPlusLeagues: number[];
	contentMessages: ContentMessages;
	couponTags: CouponTags;
	coupons: Coupons;
	crmPostBetSignposting: CrmPostBetSignposting;
	crmPromotions: CrmPromotions;
	crmPromotionsBanners: CrmPromotionsBanners;
	crmTokens: CrmTokens;
	eSports: ESportsConfig;
	earlyPayout: EarlyPayout;
	earlyPayoutErrors: EarlyPayoutErrors;
	earlyPayoutPushErrors: EarlyPayoutPushErrorItem[];
	earlyPayoutSettings: EarlyPayoutSettings;
	earlyPayoutV2: EarlyPayoutV2;
	event: Event;
	eventGameNotes: EventGameNotes;
	eventGrid: EventGrid;
	eventGridMarketHeaders: EventGridMarketHeaders;
	eventScoreboard: EventScoreboard;
	favouritesDashboard: FavouritesDashboard;
	favouritesMessages: FavouritesMessages;
	fixture: Fixture;
	formCoupon: FormCoupon;
	freeBetOverview: FreeBetOverview;
	geoLocation: GeoLocation;
	globalMessages: GlobalMessages;
	greyhoundRacing: GreyHoundRacing;
	horseRacing: HorseRacing;
	linkExternalAccounts: LinkExternalAccounts;
	links: {[key: string]: LinkTemplateForClient;};
	liveAnimation: LiveAnimation;
	liveNavigation: LiveNavigation;
	liveSportsVisualizationTranslation: LiveSportsVisualizationTranslation;
	lobby: Lobby;
	marketHelp: MarketHelp;
	marketSubtypeLogo: MarketSubtypeLogo;
	marquee: Marquee;
	media: Media;
	media_NotLoggedin: Media_NotLoggedin;
	menus: Menus;
	modalDialogs: ModalDialogs;
	multiBuilder: MultiBuilder;
	multiRandomGenerator: MultiRandomGenerator;
	myBets: MyBets;
	myBets_FilterView: MyBetsFilterView;
	myBets_Print: MyBetsPrint;
	nativeAlerts?: NativeAlert;
	ngrxReports: NgrxReports;
	oddsDisplay: OddsDisplay;
	quickBet: QuickBet;
	quickBetButtonStates: QuickBetButtonStates;
	quickBetHelp: QuickBetHelp;
	quickBetMessages: QuickBetMessages;
	quickBetModalDialog: QuickBetModalDialog;
	quickBetNumpad: QuickBetNumpad;
	reBet: ReBet;
	returnsMessage: ReturnsMessage;
	scoreboardPlayerStats: {[key: string]: {[key: string]: string;};};
	scoreboardPointsOmit: number[];
	seo: Seo;
	shareMyBet: ShareMyBet;
	showcaseMessages: ShowcaseMessages;
	stakeChanges: StakeChanges;
	statistics: SitecoreStatistics;
	systemBets: SystemBets;
	teamGrouping: TeamGrouping;
	toastLiveNotifications: ToastLiveNotifications;
	toastNotifications: ToastNotifications;
	widgets: Widgets;
	panicButtonMessages: PanicButtonMessages;
}

export function sitecoreFactory(service: LazyClientConfigService): Sitecore {
	return service.get(Sitecore);
}

@LazyClientConfig({ key:'msSitecoreEventDetails' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sitecoreEventDetailsConfigFactory,
})
export class SitecoreEventDetailsConfig extends LazyClientConfigBase {
	eventDetails: EventDetailsModel;
	onboarding: {[key: string]: string;};
	sitemapOrder?: EventDetailsSitemapDictionary[];
}

export function sitecoreEventDetailsConfigFactory(service: LazyClientConfigService): SitecoreEventDetailsConfig {
	return service.get(SitecoreEventDetailsConfig);
}

@LazyClientConfig({ key:'msSitecoreGameMessages' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sitecoreGameMessagesConfigFactory,
})
export class SitecoreGameMessagesConfig extends LazyClientConfigBase {
	secondaryBroadcastInfo?: SecondaryBroadcastInfo;
}

export function sitecoreGameMessagesConfigFactory(service: LazyClientConfigService): SitecoreGameMessagesConfig {
	return service.get(SitecoreGameMessagesConfig);
}

@LazyClientConfig({ key:'msSitecoreMobileConfirmation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sitecoreMobileConfirmationConfigFactory,
})
export class SitecoreMobileConfirmationConfig extends LazyClientConfigBase {
	dialogCloseButtonText: string;
	dialogConfirmButtonText: string;
	dialogHeading: string;
	dialogUpdateButtonText: string;
	multiFactorAuthMessage: string;
	propertyName: string;
	updateMobileMessage: string;
	updatePhoneNumberURL: string;
}

export function sitecoreMobileConfirmationConfigFactory(service: LazyClientConfigService): SitecoreMobileConfirmationConfig {
	return service.get(SitecoreMobileConfirmationConfig);
}

@LazyClientConfig({ key:'msSitecoreMyBets' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sitecoreMyBetsConfigFactory,
})
export class SitecoreMyBetsConfig extends LazyClientConfigBase {
	myBets: {[key: string]: string;};
	myBetsTax: {[key: string]: string;};
	myBets_NotLoggedIn?: ClientViewTemplate;
}

export function sitecoreMyBetsConfigFactory(service: LazyClientConfigService): SitecoreMyBetsConfig {
	return service.get(SitecoreMyBetsConfig);
}

@LazyClientConfig({ key:'msSitecorePopularBets' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sitecorePopularBetsConfigFactory,
})
export class SitecorePopularBetsConfig extends LazyClientConfigBase {
	messages: {[key: string]: string;};
}

export function sitecorePopularBetsConfigFactory(service: LazyClientConfigService): SitecorePopularBetsConfig {
	return service.get(SitecorePopularBetsConfig);
}

@LazyClientConfig({ key:'msSitecoreTokenCardDetails' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sitecoreTokenCardDetailsConfigFactory,
})
export class SitecoreTokenCardDetailsConfig extends LazyClientConfigBase {
	betAndGetCardDetailsQualify?: TokenCardDetailsReplaceParameters;
	freeBetCardDetailsQualify?: TokenCardDetailsReplaceParameters;
	messages?: TokenCardDetailsMessages;
	oddsBoostCardDetailsQualify?: TokenCardDetailsReplaceParameters;
	riskFreeCardDetailsQualify?: TokenCardDetailsReplaceParameters;
}

export function sitecoreTokenCardDetailsConfigFactory(service: LazyClientConfigService): SitecoreTokenCardDetailsConfig {
	return service.get(SitecoreTokenCardDetailsConfig);
}

@LazyClientConfig({ key:'msSitemapSitecore' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sitemapSitecoreFactory,
})
export class SitemapSitecore extends LazyClientConfigBase {
	AllLeagues: string;
	AllTournaments: string;
	Calendar: string;
	Close: string;
	ConferenceDrawerTooltip: string;
	CouponDrawerSubtitle: string;
	CouponDrawerTooltip: string;
	Coupons: string;
	Esports: string;
	Favourites: string;
	Featured: string;
	LeagueDrawerTooltip: string;
	Live: string;
	LiveVideo: string;
	MultiBuilder: string;
	MultiSportsHub: string;
	Outrights: string;
	SeeAll: string;
	SelectConference: string;
	SelectLeague: string;
	SelectTournament: string;
	Specials: string;
	Standings: string;
	TournamentDrawerTooltip: string;
}

export function sitemapSitecoreFactory(service: LazyClientConfigService): SitemapSitecore {
	return service.get(SitemapSitecore);
}

@ClientConfig({ key:'msSkeleton' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: skeletonConfigFactory,
})
export class SkeletonConfig {
	isEnabled: boolean;
	maxtimeToSkeletonDuration: number;
	skeletonAniminationStartAt: number;
}

export function skeletonConfigFactory(service: ClientConfigService): SkeletonConfig {
	return service.get(SkeletonConfig);
}

@LazyClientConfig({ key:'msSpeculativeLink' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: speculativeLinkConfigFactory,
})
export class SpeculativeLinkConfig extends LazyClientConfigBase {
	isEnabled: boolean;
}

export function speculativeLinkConfigFactory(service: LazyClientConfigService): SpeculativeLinkConfig {
	return service.get(SpeculativeLinkConfig);
}

@LazyClientConfig({ key:'msSportMarkets' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sportMarketsConfigurationFactory,
})
export class SportMarketsConfiguration extends LazyClientConfigBase {
	markets: {[key: number]: {[key: string]: string;};};
}

export function sportMarketsConfigurationFactory(service: LazyClientConfigService): SportMarketsConfiguration {
	return service.get(SportMarketsConfiguration);
}

@ClientConfig({ key:'msSportsApiVersion' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: sportsApiVersionConfigFactory,
})
export class SportsApiVersionConfig {
	sportsApiVersion: string;
	sportsApiVersionHeaderName: string;
}

export function sportsApiVersionConfigFactory(service: ClientConfigService): SportsApiVersionConfig {
	return service.get(SportsApiVersionConfig);
}

@ClientConfig({ key:'msSports' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: sportsConfigFactory,
})
export class SportsConfig {
	competitionLandingPage: number[];
	competitionOfferPriority: SportOfferOrder;
	topCompetitions: number[];
	virtuals: VirtualSportsConfiguration;
}

export function sportsConfigFactory(service: ClientConfigService): SportsConfig {
	return service.get(SportsConfig);
}

@ClientConfig({ key:'msSportsProductMenu' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: sportsProductMenuConfigFactory,
})
export class SportsProductMenuConfig {
	recentlyVisitedExpirationDays: number;
	version: string;
}

export function sportsProductMenuConfigFactory(service: ClientConfigService): SportsProductMenuConfig {
	return service.get(SportsProductMenuConfig);
}

@LazyClientConfig({ key:'msSportsProductMenuConfigV2' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sportsProductMenuConfigV2Factory,
})
export class SportsProductMenuConfigV2 extends LazyClientConfigBase {
	allSportsSection: AllSportsSection;
	popularSection: PopularSection;
	recentlyVisitedSection: RecentlyVisitedSection;
}

export function sportsProductMenuConfigV2Factory(service: LazyClientConfigService): SportsProductMenuConfigV2 {
	return service.get(SportsProductMenuConfigV2);
}

@LazyClientConfig({ key:'msSportsProductMenuSitecore' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sportsProductMenuSitecoreFactory,
})
export class SportsProductMenuSitecore extends LazyClientConfigBase {
	AllLeaguesClose: string;
	AllLeaguesHeader: string;
}

export function sportsProductMenuSitecoreFactory(service: LazyClientConfigService): SportsProductMenuSitecore {
	return service.get(SportsProductMenuSitecore);
}

@ClientConfig({ key:'msSportsUser' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: sportsUserConfigFactory,
})
export class SportsUserConfig {
	associatedAccount: string;
	cdnaProfileDetails: CdnaUserProfile;
	clientIP: string;
	columnSize?: number;
	isEarlyPayoutAllowed: boolean;
	isEligibleForNewCustomerOffer: boolean;
	isQuickDepositEnabled: boolean;
	jurisdiction: string;
}

export function sportsUserConfigFactory(service: ClientConfigService): SportsUserConfig {
	return service.get(SportsUserConfig);
}

@LazyClientConfig({ key:'msSportsUserSettings' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: sportsUserSettingsFactory,
})
export class SportsUserSettings extends LazyClientConfigBase {
	bettingSettings: UserSettingsData;
	configSettings: ConfigSettings;
}

export function sportsUserSettingsFactory(service: LazyClientConfigService): SportsUserSettings {
	return service.get(SportsUserSettings);
}

@ClientConfig({ key:'msStake' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: stakeConfigFactory,
})
export class StakeConfig {
	addToStakeValues: {[key: string]: number[];};
	checkUserStakeLimit: boolean;
	isMaxStakePerBetslipStrategy: boolean;
	maxLeadingDigits: {[key: string]: number;};
	maxStakeByCurrency?: {[key: string]: number;};
	minStakeThresholdValue: number;
	showStakeLimitInfo: boolean;
	stakeValuesByCurrency: {[key: string]: number[];};
}

export function stakeConfigFactory(service: ClientConfigService): StakeConfig {
	return service.get(StakeConfig);
}

@ClientConfig({ key:'msStatistics' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: statisticsConfigFactory,
})
export class StatisticsConfig {
	directMatchesBarColors: DirectMatchesBarColors;
	expandedLeagueTablesCount: number;
	isPerformStatsEnabled: boolean;
	pitcherEnabledSports: number[];
	promotionHighlightColors: {[key: number]: {[key: number]: string;};};
	promotionHighlightColorsWithPositions: PromotionHighlightColorsWithPositions;
	statisticsUrlTemplate: string;
	supportedSports: number[];
	teamRankEnabledSports: number[];
	teamRecordsFormat: {[key: number]: CompetitionRecordsFormat;};
}

export function statisticsConfigFactory(service: ClientConfigService): StatisticsConfig {
	return service.get(StatisticsConfig);
}

@ClientConfig({ key:'msStory' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: storyConfigFactory,
})
export class StoryConfig {
	minSwipeDistance: number;
}

export function storyConfigFactory(service: ClientConfigService): StoryConfig {
	return service.get(StoryConfig);
}

@ClientConfig({ key:'msStylingGrid' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: stylingGridConfigurationFactory,
})
export class StylingGridConfiguration {
	participantImageSportBlacklist: number[];
	showParticipantBoldName: boolean;
}

export function stylingGridConfigurationFactory(service: ClientConfigService): StylingGridConfiguration {
	return service.get(StylingGridConfiguration);
}

@ClientConfig({ key:'msTaxation' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: taxationConfigFactory,
})
export class TaxationConfig {
	isTaxationEnabled: boolean;
	taxableSports: number[];
}

export function taxationConfigFactory(service: ClientConfigService): TaxationConfig {
	return service.get(TaxationConfig);
}

@ClientConfig({ key:'msTeamPages' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: teamPagesConfigFactory,
})
export class TeamPagesConfig {
	blacklistedCompetitions: string[];
	imageProfileEnabledSportIds: number[];
	isEnabled: boolean;
	isEnabledOnHomeLobby: boolean;
	isNewBadgeEnabled: boolean;
	maxCompetitions: number;
	minTeamsOnHomeLobby: number;
	sportsToDisplayLogos: number[];
	whitelistedSports: number[];
}

export function teamPagesConfigFactory(service: ClientConfigService): TeamPagesConfig {
	return service.get(TeamPagesConfig);
}

@LazyClientConfig({ key:'msTeamPagesModule' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: teamPagesModuleConfigFactory,
})
export class TeamPagesModuleConfig extends LazyClientConfigBase {
	backgroundImages: ScoreboardBackgroundModel;
	teamPageItems: TeamPagesViewModel;
}

export function teamPagesModuleConfigFactory(service: LazyClientConfigService): TeamPagesModuleConfig {
	return service.get(TeamPagesModuleConfig);
}

@ClientConfig({ key:'msTeaserBet' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: teaserBetConfigFactory,
})
export class TeaserBetConfig {
	allowedCompetitions: string[];
	allowedTemplateIds: TeaserBetTemplateIds[];
	isEnabled: boolean;
	isTeaserPickPriceTrackingEnabled: boolean;
	pointsSliderConfig: TeaserBetPointsSlider[];
	priceTableCache: TeaserPriceTableCacheDuration;
}

export function teaserBetConfigFactory(service: ClientConfigService): TeaserBetConfig {
	return service.get(TeaserBetConfig);
}

@ClientConfig({ key:'msTimeFilter' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: timeFilterConfigFactory,
})
export class TimeFilterConfig {
	isTimeFilterEnabled: boolean;
	timeFilterOptions: string[];
	timeFilterOptionsForCoupons: string[];
}

export function timeFilterConfigFactory(service: ClientConfigService): TimeFilterConfig {
	return service.get(TimeFilterConfig);
}

@ClientConfig({ key:'msTopEvents' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: topEventsConfigFactory,
})
export class TopEventsConfig {
	maxEventsPerPod: number;
	maxOptionsPerOutrightPod: number;
}

export function topEventsConfigFactory(service: ClientConfigService): TopEventsConfig {
	return service.get(TopEventsConfig);
}

@LazyClientConfig({ key:'msTopNavigation' , product: ClientConfigProductName.SPORTS, reload: true })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: topNavigationConfigFactory,
})
export class TopNavigationConfig extends LazyClientConfigBase {
	afterSports: NavigationBarItem[];
	beforeSports: NavigationBarItem[];
	fixedItems: NavigationBarItem[];
	isDesktopNavigationCompact: boolean;
	maxSports: number;
	sportsConfig: NavigationSportsConfig;
	topNavigationItems: NavigationBarItem[];
}

export function topNavigationConfigFactory(service: LazyClientConfigService): TopNavigationConfig {
	return service.get(TopNavigationConfig);
}

@ClientConfig({ key:'msTournamentsData' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: tournamentsDataConfigFactory,
})
export class TournamentsDataConfig {
	tournaments: Tournament[];
}

export function tournamentsDataConfigFactory(service: ClientConfigService): TournamentsDataConfig {
	return service.get(TournamentsDataConfig);
}

@LazyClientConfig({ key:'msTrapImages' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [LazyClientConfigService],
	useFactory: trapImagesFactory,
})
export class TrapImages extends LazyClientConfigBase {
	dogTrap: TrapImage[];
}

export function trapImagesFactory(service: LazyClientConfigService): TrapImages {
	return service.get(TrapImages);
}

@ClientConfig({ key:'msUKGC' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: uKGCConfigFactory,
})
export class UKGCConfig {
	isPotentialReturnsEnabledForSingle: boolean;
}

export function uKGCConfigFactory(service: ClientConfigService): UKGCConfig {
	return service.get(UKGCConfig);
}

@ClientConfig({ key:'msWidget' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: widgetConfigFactory,
})
export class WidgetConfig {
	defaultRightColumn: Widget<unknown>[];
	orderedRenderingTimeout: number;
	pageRefreshInterval: PageRefreshConfig[];
}

export function widgetConfigFactory(service: ClientConfigService): WidgetConfig {
	return service.get(WidgetConfig);
}

@ClientConfig({ key:'msWorldCupHub' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: worldCupHubConfigFactory,
})
export class WorldCupHubConfig {
	isNewBadgeEnabled: boolean;
	wcEventHubURLs: CultureUrl[];
}

export function worldCupHubConfigFactory(service: ClientConfigService): WorldCupHubConfig {
	return service.get(WorldCupHubConfig);
}

@ClientConfig({ key:'msYahooOpenId' , product: ClientConfigProductName.SPORTS })
@Injectable({
	providedIn: 'root',
	deps: [ClientConfigService],
	useFactory: yahooOpenIdConfigFactory,
})
export class YahooOpenIdConfig {
	enableOnBetPlacement: boolean;
	nativeAppCookie: string;
}

export function yahooOpenIdConfigFactory(service: ClientConfigService): YahooOpenIdConfig {
	return service.get(YahooOpenIdConfig);
}

