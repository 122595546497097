import { Injectable, OnDestroy, Optional } from '@angular/core';

import { RewardTargetType } from '@bpos/v1/sports-promo/tokens';
import { SimpleCallback } from '@frontend/sports/common/base-utils';
import { CrmConfig } from '@frontend/sports/common/client-config-data-access';
import { ApiService } from '@frontend/sports/common/core/feature/http';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { TimerService } from '../common/timer.service';
import { IRewardTokenData, RewardTokenType } from '../tokens-base/token-base.models';
import { UserService } from '../user/services/user.service';
import { CrmOfferLoaderRequestParamsBaseService } from './crm-offer-loader-request-params-base.service';
import { Campaign, OffersResponse } from './crm-offer.server.model';

@Injectable({
    providedIn: 'root',
})
export class CrmOfferLoaderService implements OnDestroy {
    private _promotions$ = new ReplaySubject<Campaign[]>(1);
    private _tokens$ = new ReplaySubject<IRewardTokenData[]>(1);
    private _promoRestricted$ = new BehaviorSubject<boolean>(false);

    readonly promotions$ = this._promotions$.asObservable();
    readonly tokens$ = this._tokens$.asObservable();
    readonly promoRestricted$ = this._promoRestricted$.asObservable();

    private interval?: SimpleCallback;

    constructor(
        private apiService: ApiService,
        private crmConfig: CrmConfig,
        private userService: UserService,
        private timerService: TimerService,
        @Optional() private crmOfferLoaderRequestParamsService: CrmOfferLoaderRequestParamsBaseService | null,
    ) {
        if (!this.crmConfig.promotions.isEnabled) {
            this._promotions$.next([]);
        }

        if (!this.crmConfig.tokens.isEnabled) {
            this._tokens$.next([]);
        }

        if (this.crmConfig.promotions.isEnabled || this.crmConfig.tokens.isEnabled) {
            this.startGettingOffers();
        }
    }

    ngOnDestroy(): void {
        this.stopRefresh();
    }

    refresh(): void {
        this.fetchOffer(true);
    }

    private startGettingOffers(forceRefresh: boolean = false): void {
        if (this.userService.isAuthenticated) {
            this.setupOfferRefresh(forceRefresh);
        } else {
            this._tokens$.next([]);
        }

        this.userService.onUserLogin$.subscribe(() => this.setupOfferRefresh());

        this.userService.onUserLogout$.subscribe(() => {
            this._promotions$.next([]);
            this._tokens$.next([]);
            this.stopRefresh();
        });
    }

    private fetchOffer(forceRefresh: boolean = false): void {
        this.apiService.get<OffersResponse>('crmOffers', this.crmOfferLoaderRequestParamsService?.params).subscribe((result) => {
            if (!forceRefresh && this.crmConfig.promotions.isEnabled) {
                this._promotions$.next(result.campaigns);
            }

            if (this.crmConfig.tokens.isEnabled) {
                this._tokens$.next(this.getTokensResponse(result));
            }

            if (this.crmConfig.promotions.isRegulatoryRestricted) {
                this._promoRestricted$.next(result.isPromoOfferRestricted);
            }

            if (result.cancelPolling) {
                this.stopRefresh();
            }
        });
    }

    private getTokensResponse(response: OffersResponse): IRewardTokenData[] {
        const oddsBoostTokens: IRewardTokenData[] = response.oddsBoostTokens.map((token) => ({
            ...token,
            rewardTokenType: RewardTokenType.OddsBoost,
        }));

        const riskFreeTokens: IRewardTokenData[] = response.riskFreeTokens.map((token) => ({
            ...token,
            rewardTokenType: RewardTokenType.RiskFreeBet,
        }));

        const freeBetTokens: IRewardTokenData[] = response.freeBetTokens.map((token) => ({
            ...token,
            rewardTokenType: RewardTokenType.FreeBet,
        }));

        const betAndGetTokens: IRewardTokenData[] = response.betAndGetTokens.map((token) => ({
            ...token,
            rewardTokenType: RewardTokenType.BetAndGet,
        }));

        const rewardTokens = [...oddsBoostTokens, ...riskFreeTokens, ...freeBetTokens, ...betAndGetTokens];

        if (!this.crmConfig.tokens.acquisitionRewardDetails.isWelcomeOfferTokenAllowed) {
            return rewardTokens.filter((x) => x.rewardTargetType !== RewardTargetType.WelcomeOffer);
        }

        return rewardTokens;
    }

    private setupOfferRefresh(forceRefresh: boolean = false): void {
        this.fetchOffer(forceRefresh);

        if (this.crmConfig.promotions.refreshInterval && !forceRefresh) {
            this.interval = this.timerService.setInterval(() => this.fetchOffer(), this.crmConfig.promotions.refreshInterval);
        }
    }

    private stopRefresh(): void {
        if (this.interval) {
            this.interval();
        }
    }
}
