import { Injectable } from '@angular/core';

import { BetBuilderProvider } from '@cds/betting-offer/add-ons';
import { BetBuilderMarketInfo, BetBuilderOptionGroupInfo, ResultState } from '@cds/betting-offer/domain-specific/bet-builder';
import { Nullable } from '@frontend/sports/common/base-utils';
import { PriceBoostConfig } from '@frontend/sports/common/client-config-data-access';
import { NativePrice } from '@frontend/sports/common/odds-lib';
import { MarqueeTile, MarqueeType } from '@frontend/sports/types/components/content';

import { BetBuilderMarket, BetBuilderOption } from '../betbuilder/model/bet-builder.model';
import { OptionGroupService } from '../event-model/helpers/option-group.service';
import { EventModel, EventOption, EventOptionGroup } from '../event-model/model/event.model';
import { BetBuilderTile, FixtureTile, VisibilityOnMarquee } from './marquee-tile.model';

@Injectable({ providedIn: 'root' })
export class MarqueeTileFactory {
    constructor(
        private optionGroupService: OptionGroupService,
        private priceBoostConfig: PriceBoostConfig,
    ) {}

    createBetBuilderTile(
        modelEvent: EventModel,
        sitecoreTile: MarqueeTile,
        fixtureId: string,
        precreatedMarketsInfo?: BetBuilderMarketInfo[],
    ): Nullable<BetBuilderTile> {
        const modelMarket = !sitecoreTile.isAutomatedMarquee
            ? modelEvent.optionGroups[0]
            : modelEvent.optionGroups.find((x) => x.id === (sitecoreTile.gameId || sitecoreTile.optionMarketId)?.toString()) || null;

        if (!modelMarket) {
            return null;
        }

        if (this.isPrecreatedOptionGroupInvalid(modelEvent)) {
            return null;
        }

        const longIds = precreatedMarketsInfo?.find((market) => market.id.toString() === modelMarket.id)?.selections;

        return {
            index: +modelEvent.id,
            sportId: sitecoreTile.sportId || modelEvent.sport.id,
            source: sitecoreTile,
            fixtureId: modelEvent.id,
            betBuilderFixtureId: fixtureId,
            imageUrl: sitecoreTile.imageUrl,
            market: this.mapMarket(modelMarket, longIds),
            name: sitecoreTile.betbuilderSelectionOverride || this.getBetbuilderName(modelEvent, modelMarket, modelEvent.precreatedOptionGroups),
            event: modelEvent,
            marqueeType: MarqueeType.BuildABet,
            showOnHomeLobby: sitecoreTile.showOnHomeLobby,
            showOnSportsLobby: sitecoreTile.showOnSportsLobby,
            crmPromotionId: sitecoreTile.crmPromotionId,
            crmPromotionVisibility: this.getVisibilityOnMarquee(sitecoreTile.crmPromotionVisibility),
            hideBuildABetBadge: sitecoreTile.hideBuildABetBadge,
            leftAlignOddsButton: sitecoreTile.leftAlignOddsButton,
            AwayIndicatorOverride: sitecoreTile.awayIndicatorOverride,
            BadgeOverrideText: sitecoreTile.badgeOverrideText,
            OverriddenTeamnames: sitecoreTile.overriddenTeamNames,
            eventOptionGroups: modelEvent.optionGroups,
        };
    }

    createMarqueeTile(sitecoreTile: MarqueeTile, modelEvent?: EventModel): Nullable<FixtureTile> {
        const tile = {} as FixtureTile;

        if (modelEvent) {
            const modelMarket = !sitecoreTile.isAutomatedMarquee
                ? this.isModelMarketBalanced(modelEvent.optionGroups)
                    ? this.optionGroupService.get(modelEvent.optionGroups)
                    : modelEvent.optionGroups[0]
                : modelEvent.optionGroups.find((x) => x.id === (sitecoreTile.gameId || sitecoreTile.optionMarketId)?.toString());

            // Sanity check for specials
            const sitecoreOptionGroupId = sitecoreTile.gameId || sitecoreTile.optionMarketId;
            const marketIdString = sitecoreOptionGroupId + '';
            if (!!modelMarket && !!sitecoreOptionGroupId && modelMarket.id !== marketIdString) {
                return null;
            }

            this.setOverridenNames(modelMarket!);

            tile.sportId = sitecoreTile.sportId;
            tile.fixtureId = sitecoreTile.fixtureId;
            tile.optionId = sitecoreTile.resultId || sitecoreTile.optionId || 0;
            tile.optionGroupId = sitecoreTile.gameId || sitecoreTile.optionMarketId || 0;
            tile.event = modelEvent;
            tile.optionGroup = modelMarket;
            tile.gridGroupId = sitecoreTile.gridGroupId ?? '';
            tile.gridGroupIds = sitecoreTile.gridGroupIds || [];
            tile.gameTemplateIds = sitecoreTile.gameTemplateIds ? sitecoreTile.gameTemplateIds.map((gt) => gt + '') : [];
            if (
                (this.priceBoostConfig.isEnabled || this.priceBoostConfig.isEnabledAPB) &&
                sitecoreTile.isPriceBoostMarquee &&
                sitecoreTile.optionId != null
            ) {
                const previousPrice = this.getTilePrice(modelEvent.optionGroups, sitecoreTile.optionId);
                if (previousPrice) {
                    tile.previousNativePrice = previousPrice;
                    tile.isPriceBoosted = true;
                }
            } else if (
                sitecoreTile.previousOdds ||
                (sitecoreTile.previousOddsNumerator && sitecoreTile.previousOddsDenominator) ||
                sitecoreTile.previousOddsUs !== undefined
            ) {
                tile.previousNativePrice = NativePrice.fromNativePriceElement({
                    odds: sitecoreTile.previousOdds,
                    numerator: sitecoreTile.previousOddsNumerator,
                    denominator: sitecoreTile.previousOddsDenominator,
                    americanOdds: sitecoreTile.previousOddsUs,
                });
            }
        }

        tile.marqueeType = sitecoreTile.marqueeType;
        tile.showOnHomeLobby = sitecoreTile.showOnHomeLobby;
        tile.showOnSportsLobby = sitecoreTile.showOnSportsLobby;
        tile.marketAndOptionNameOverride = sitecoreTile.marketAndOptionNameOverride;
        tile.eventNameOverride = sitecoreTile.eventNameOverride;
        tile.eventNameVisibility = sitecoreTile.eventNameVisibility ?? '';
        tile.marketAndOptionNameVisibility = sitecoreTile.marketAndOptionNameVisibility ?? '';
        tile.link = sitecoreTile.link;
        tile.imageUrl = sitecoreTile.imageUrl;
        tile.imageAlt = sitecoreTile.imageAlt;
        tile.trafficId = sitecoreTile.trafficId;
        tile.badgeAndRibbonVisibilityOption = sitecoreTile.badgeAndRibbonVisibilityOption ?? '';
        tile.noEventSportIds = sitecoreTile.noEventSportIds;
        tile.badgeTitle = sitecoreTile.badgeTitleOverride;
        tile.crmPromotionId = sitecoreTile.crmPromotionId;
        tile.crmPromotionVisibility = this.getVisibilityOnMarquee(sitecoreTile.crmPromotionVisibility);
        tile.marketType = sitecoreTile.marketType;
        tile.fallbackMarketType = sitecoreTile.fallbackMarketType;
        tile.ogpOverlay = sitecoreTile.ogpOverlay;

        return tile;
    }

    private getVisibilityOnMarquee(visibility?: string): VisibilityOnMarquee {
        return visibility !== undefined ? VisibilityOnMarquee[visibility] : VisibilityOnMarquee.All;
    }

    private isModelMarketBalanced(optionGroups: EventOptionGroup[]): boolean {
        return optionGroups.some((opt) => !!opt.balanced);
    }

    private setOverridenNames(optionGroup: EventOptionGroup): boolean {
        if (!optionGroup || !optionGroup.options || !optionGroup.options.length) {
            return false;
        }

        for (const result of optionGroup.options) {
            if (!result.name || result.overridenName) {
                continue;
            }

            result.overridenName = result.name;
        }

        return true;
    }

    private mapMarket(optionMarket: EventOptionGroup, longIds?: string[]): BetBuilderMarket {
        return {
            id: Number(optionMarket.id),
            name: optionMarket.name,
            nameSign: '',
            option: this.mapOption(optionMarket.options[0]),
            longIds,
        };
    }

    private mapOption(option: EventOption): BetBuilderOption {
        return {
            id: option.id,
            optionPrice: {
                id: option.priceId!,
                nativePrice: option.nativePrice,
            },
        };
    }

    private getTilePrice(optionGroups: EventOptionGroup[], optionId: number): NativePrice | undefined {
        return optionGroups.flatMap((optionGroup) => optionGroup.options).find((option) => option.id === optionId)?.nativePrice;
    }

    private getBetbuilderName(
        modelEvent: EventModel,
        modelMarket: EventOptionGroup | null,
        precreatedOptionGroupInfo?: BetBuilderOptionGroupInfo[],
    ): string {
        if (!modelMarket && !precreatedOptionGroupInfo) {
            return '';
        }

        if (precreatedOptionGroupInfo?.length) {
            let name = '';

            precreatedOptionGroupInfo[0].options.forEach((option) => {
                const market = modelEvent.optionGroups.find((x) => x.id === option.marketId.toString());

                if (market) {
                    const optionName = market.name + ' - ' + market.options.find((o) => o.id === option.id)?.name;
                    name = name + ' | ' + optionName;
                }
            });

            return name.slice(3);
        }

        if (modelMarket) {
            return modelMarket.name.replace(/;/g, ' | ');
        }

        return '';
    }

    private isPrecreatedOptionGroupInvalid(modelEvent: EventModel): boolean {
        if (modelEvent.precreatedOptionGroups && modelEvent.precreatedOptionGroups.length) {
            const groupPricing = modelEvent.precreatedOptionGroups[0].builderOptionPricing;
            const pricingInvalid =
                !groupPricing.legInformation ||
                !groupPricing.legInformation.length ||
                groupPricing.legInformation.some((l) => l.resultState !== ResultState.Open) ||
                !groupPricing.odds;

            const visibleOptions: EventOption[] = [];
            modelEvent.optionGroups.forEach((og) => {
                if (og.online || (!og.online && og.hidden !== null && og.hidden === false)) {
                    visibleOptions.push(...og.options);
                }
            });
            const visibilityInvalid = !modelEvent.precreatedOptionGroups[0].options.every((o) => visibleOptions.some((vo) => vo.id === o.id));

            return modelEvent.betBuilderInfo?.betBuilderProvider !== BetBuilderProvider.BybFootball
                ? pricingInvalid || visibilityInvalid
                : visibilityInvalid;
        }

        return false;
    }
}
