import { Injectable } from '@angular/core';

import { InternalFeaturesConfig } from '@frontend/sports/common/client-config-data-access';
import { ApiService } from '@frontend/sports/common/core/feature/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EPSDetailedLogger {
    constructor(
        private api: ApiService,
        private internalConfig: InternalFeaturesConfig,
    ) {}

    private queueForPush: any[] = [];
    private queueCheckResponse: any[] = [];
    private timer: any;

    logPush(data: any): void {
        if (!this.internalConfig.isEPSDetailedLoggingEnabled) {
            return;
        }
        this.queueForPush.push(data);
        this.sendLogs();
    }

    logCheckReponse(data: any): void {
        if (!this.internalConfig.isEPSDetailedLoggingEnabled) {
            return;
        }

        this.queueCheckResponse.push(data);
        this.sendLogs();
    }

    private sendLogs(): void {
        if (this.timer != null) {
            return;
        }

        this.timer = setTimeout(async () => {
            if (this.queueForPush.length) {
                await firstValueFrom(this.api.post('epslogger/logpush	', this.queueForPush.slice(0, 50)));
            }
            if (this.queueCheckResponse.length) {
                await firstValueFrom(this.api.post('epslogger/logcheckresponse', this.queueCheckResponse.slice(0, 50)));
            }
            this.queueForPush = [];
            this.queueCheckResponse = [];
            this.timer = null;
        }, 1000);
    }
}
