import { Injectable } from '@angular/core';

import { SimpleCallback } from '@frontend/sports/common/base-utils';
import { SeoConfig } from '@frontend/sports/common/client-config-data-access';
import { ApiService } from '@frontend/sports/common/core/feature/http';
import { DeviceService } from '@frontend/vanilla/core';
import { clone } from 'lodash-es';
import { finalize } from 'rxjs/operators';

import { TimerService } from '../common/timer.service';

export interface LogRecord {
    source: string;
    url: string;
}

@Injectable({ providedIn: 'root' })
export class SeoLoggerService {
    private records: LogRecord[] = [];
    private recordsTimer?: SimpleCallback;

    constructor(
        private device: DeviceService,
        private api: ApiService,
        private timer: TimerService,
        private seo: SeoConfig,
    ) {}

    log(record: LogRecord): void {
        if (!this.device.isRobot || !this.seo.isClientCatchallLoggingEnabled) {
            return;
        }

        this.records.push(record);

        if (this.recordsTimer) {
            return;
        }

        this.recordsTimer = this.timer.setTimeout(() => {
            this.api
                .post('seo/log', { records: clone(this.records) })
                .pipe(
                    finalize(() => {
                        this.records.splice(0);
                        this.recordsTimer = undefined;
                    }),
                )
                .subscribe();
        }, this.seo.clientLoggingDebounce);
    }
}
